<template>
  <div>
    <div
      class="card card-custom gutter-b"
      style="height: 60vh; overflow-y: auto"
    >
      <div class="card-header border-0 py-5">
        <h3 class="card-title font-weight-bolder text-dark">
          Weeks
          <span class="ml-5"
            ><span
              class="label label-lg label-inline label-light-primary font-weight-bold"
              >{{ range.start | formatDate }}</span
            ></span
          >
          <span
            ><span class="flex-shrink-0 my-2 mx-3"
              ><i class="la la-arrow-right"></i></span
          ></span>
          <span
            ><span
              class="label label-lg label-inline label-light-primary font-weight-bold"
              >{{ range.end | formatDate }}</span
            ></span
          >
        </h3>
        <div class="card-toolbar"></div>
      </div>
      <div
        class="card-body mx-0 px-0"
        style="height: 60vh; width: 100%; display: flex; align-items: center"
      >
        <apexchart
          v-if="isReady"
          :options="options"
          :series="series"
          width="100%"
          height="100%"
          style="height: 100%; width: 100%"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { getWeeksMeta } from '@/core/helpers/helpers'
import VueApexCharts from 'vue-apexcharts'
import { mapGetters } from 'vuex'

export default {
  name: 'WeekTrafficRefactored',
  components: {
    apexchart: VueApexCharts,
  },
  props: {
    chartData: {
      type: Array,
    },
    range: {
      type: Object,
    },
  },
  data() {
    return {
      options: {
        chart: {
          type: 'bar',
          height: 350,
          stacked: true,
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: true,
          offsetX: 0,
          offsetY: 0,
          formatter(val) {
            if (val !== null) {
              return val
                .toFixed(2)
                .replace('.', ',')
                .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
            }
            return val
          },
        },
        xaxis: {
          categories: [],
          labels: {
            show: true,
            style: {
              fontSize: '12px',
              fontFamily: 'Poppins, Helvetica, "sans-serif"',
              fontWeight: 400,
              cssClass: 'week-label',
            },
            formatter(value) {
              return value
            },
          },
        },
        yaxis: {
          show: true,
          labels: {
            show: true,
            formatter(val) {
              if (val) {
                return (
                  val
                    .toFixed(2)
                    .replace('.', ',')
                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') + ' €'
                )
              }
              return val
            },
          },
        },
        legend: {
          show: false,
        },
      },
      series: [],
      isReady: false,
    }
  },
  computed: {
    ...mapGetters(['currentUserCompanyModule']),
  },
  mounted() {
    const mq = window.matchMedia('(min-width: 480px)')

    if (!mq.matches) {
      this.options.chart.type = 'area'
      this.options.xaxis.labels.show = false
      this.options.yaxis.show = false
    }

    if (this.currentUserCompanyModule[0] === 'gaming')
      this.options.legend.show = true

    setTimeout(() => {
      this.isReady = true
      setTimeout(() => {
        const weeks = getWeeksMeta(this.range.start, this.range.end)
        weeks[0].forEach((week) => {
          document.querySelectorAll('.week-label').forEach((element) => {
            let v = document.getElementById(
              element.getElementsByTagName('tspan')[0].id
            )
            if (!week.isFull) {
              if (week.week == v.innerHTML) {
                v.style.fill = '#ff0000'
                v.style.height = 200
                v.innerHTML = week.week + ' (Not Full Week)'
              }
            }
          })

          this.options.xaxis.categories.push(week.week)
        })

        this.chartData.map((data) => {
          const filteredArray = weeks[0].map((week) => {
            return data.data[week.week - 1]
          })
          const seriesData = {
            name: data.name,
            data: filteredArray,
          }
          this.series.push(seriesData)
        })
      }, 200)
    }, 500)

    console.log('thias.series', this.series)
  },
}
</script>
