<template>
  <div>
    <notifications group="notification" position="top right" />
    <b-alert :show="showAlert" variant="danger">
      <span>{{ alertMsg }}</span>
    </b-alert>
    <div class="row">
      <div class="col-md-9">
        <div class="card card-custom gutter-b">
          <div class="card-body px-2">
            <DualListBoxDevices
              :source="devices"
              :destination="selectedDevices"
              label="name"
              @onChangeList="onChangeList"
            />
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="card card-custom gutter-b">
          <div class="card-body">
            <DatePicker
              ref="picker"
              v-model="range"
              mode="dateTime"
              :attributes="attributes"
              :masks="masks"
              locale="hr"
              is-range
              is24hr
              is-expanded
              :columns="$screens({ default: 1, lg: 1 })"
              :max-date="new Date()"
            >
              <template v-slot:footer>
                <div class="bg-gray-100 text-center p-2 border-t rounded-b-lg">
                  <button
                    @click="setStartEndDate()"
                    class="btn btn-light-primary btn-sm btn-block"
                  >
                    Start/End
                  </button>
                </div>
              </template>
            </DatePicker>
            <button
              @click="last7Days"
              class="btn btn-light-primary btn-block font-weight-bold mt-2"
            >
              Last 7 days
            </button>
            <button
              @click="last30Days"
              class="btn btn-light-primary btn-block font-weight-bold"
            >
              Last 30 days
            </button>
            <button
              @click="thisMonth"
              class="btn btn-light-primary btn-block font-weight-bold"
            >
              This month
            </button>
            <button
              @click="lastMonth"
              class="btn btn-light-primary btn-block font-weight-bold"
            >
              Last month
            </button>
            <button
              @click="reset"
              class="btn btn-light-danger btn-block font-weight-bold"
            >
              Reset
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <button
          v-if="!isGenerated"
          class="btn btn-primary btn-block font-weight-bold"
          @click="generate"
        >
          Prepare Data
        </button>
        <button
          v-else
          class="btn btn-primary btn-block font-weight-bold"
          @click="resetAll"
        >
          Reset All
        </button>
        <div v-if="isGenerated" class="card card-custom">
          <div class="card-body">
            <b>Selected devices:</b> {{ selectedDevices.length }}<br />
            <b>Date from:</b> {{ range.start | formatDateTime }} <br />
            <b>Date to:</b> {{ range.end | formatDateTime }}
          </div>
        </div>
        <button
          v-if="isGenerated"
          class="btn btn-primary btn-block font-weight-bold"
          @click="generateExport"
        >
          Generate
        </button>
        <div class="row">
          <div class="col-12 text-center my-5">
            <b-spinner
              v-if="showSpinner"
              variant="primary"
              label="Spinning"
            ></b-spinner>
          </div>
        </div>
      </div>
    </div>

    <div v-if="isDataGenerate" class="row">
      <div class="col-md-12">
        <DevicesTrafficNew
          v-if="isGenerated && isDataGenerate"
          :devices="payloadDevices"
          :no-traffic-devices="payloadNoTrafficDevices"
          :download-excel-url="downloadExcelUrl"
          :range="range"
        />
      </div>
    </div>
    <div
      v-if="
        isGenerated &&
        isDataGenerate &&
        currentActiveModuleComponents?.reports.reportByDaysInRange
      "
      class="row"
    >
      <div class="col-md-12">
        <DaysTrafficNew
          v-if="isGenerated"
          :type="'device'"
          :transactions="transactions"
          :range="range"
        />
      </div>
    </div>
    <div
      v-if="
        isGenerated &&
        isDataGenerate &&
        currentActiveModuleComponents?.reports.reportWeek
      "
      class="row"
    >
      <div class="col-md-12">
        <WeekTrafficNew
          v-if="isGenerated"
          :transactions="transactions"
          :range="range"
        />
      </div>
    </div>
    <div
      v-if="
        isGenerated &&
        isDataGenerate &&
        currentActiveModuleComponents?.reports.reportMonth
      "
      class="row"
    >
      <div class="col-md-12">
        <MonthTrafficNew
          v-if="isGenerated"
          :transactions="transactions"
          :range="range"
        />
      </div>
    </div>
    <div
      v-if="
        isGenerated &&
        isDataGenerate &&
        currentActiveModuleComponents?.reports.reportYear
      "
      class="row"
    >
      <div class="col-md-12">
        <YearTrafficNew
          v-if="isGenerated"
          :transactions="transactions"
          :range="range"
        />
      </div>
    </div>
    <div
      v-if="
        isGenerated &&
        isDataGenerate &&
        currentActiveModuleComponents?.reports.AverageByDaysInWeek
      "
      class="row"
    >
      <div class="col-md-12">
        <WeekDaysTrafficNew
          v-if="isGenerated"
          :transactions="transactions"
          :range="range"
        />
      </div>
    </div>
    <div
      v-if="
        isGenerated &&
        isDataGenerate &&
        currentActiveModuleComponents?.reports.AverageByHoursOfTheDay
      "
      class="row"
    >
      <div class="col-md-12">
        <HoursTrafficNew
          v-if="isGenerated"
          :transactions="transactions"
          :range="range"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import DatePicker from 'v-calendar/lib/components/date-picker.umd'
import DualListBoxDevices from '@/view/content/components/dual-list-box/DualListBoxDevices'
import DevicesTrafficNew from '@/view/pages/finance-report/_components/DevicesTrafficNew'
import DaysTrafficNew from '@/view/pages/finance-report/_components/DaysTrafficNew'
import WeekTrafficNew from '@/view/pages/finance-report/_components/WeekTrafficNew'
import MonthTrafficNew from '@/view/pages/finance-report/_components/MonthTrafficNew'
import YearTrafficNew from '@/view/pages/finance-report/_components/YearTrafficNew'
import WeekDaysTrafficNew from '@/view/pages/finance-report/_components/WeekDaysTrafficNew'
import HoursTrafficNew from '@/view/pages/finance-report/_components/HoursTrafficNew'
import ApiService from '@/core/services/api.service'
import format from 'date-fns/format'
import add from 'date-fns/add'
import startOfMonth from 'date-fns/startOfMonth'
import getMonth from 'date-fns/getMonth'
import addMonths from 'date-fns/addMonths'
import getYear from 'date-fns/getYear'
import endOfMonth from 'date-fns/endOfMonth'
import addDays from 'date-fns/addDays'
import axios from 'axios'

export default {
  name: 'DeviceNewFinanceReport',
  components: {
    DatePicker,
    DualListBoxDevices,
    DevicesTrafficNew,
    DaysTrafficNew,
    WeekTrafficNew,
    MonthTrafficNew,
    YearTrafficNew,
    WeekDaysTrafficNew,
    HoursTrafficNew,
  },
  data() {
    return {
      devices: [],
      selectedDevices: [],
      url: null,
      totalItems: null,
      range: {
        start: null,
        end: null,
      },
      masks: {
        input: 'dd.MM.yyyy - HH:mm:ss',
      },
      attributes: [
        {
          key: 'today',
          highlight: true,
          dates: null,
        },
      ],

      showAlert: false,
      alertMsg: null,

      isGenerated: false,
      showSpinner: false,
      isLoaded: false,
      downloadExcelUrl: null,

      payloadDevices: [],
      payloadNoTrafficDevices: [],
      transactions: [],
      isDataGenerate: false,
    }
  },
  computed: {
    ...mapGetters([
      'currentUserRole',
      'currentUserCompany',
      'currentActiveModuleComponents',
      'currentUserPersonalInfo',
      'currentUserCompanyOib',
    ]),
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Device', route: '/finance-report/location' },
    ])

    if (
      this.currentUserRole === 'ROLE_ADMIN' ||
      this.currentUserRole === 'ROLE_WORKER'
    ) {
      ApiService.get(
        'devices',
        `?company=${this.currentUserCompany.replace(
          '/api/v1/companies/',
          '',
        )}&pagination=false`,
      ).then(({ data }) => {
        this.totalItems = data['hydra:member'].length
        this.devices = [...data['hydra:member']]
        this.devices = this.devices.filter(
          (device) =>
            (device.isSaldoReducer !== true) & (device.isDemoDevice !== true),
        )
        this.devices.forEach((device, index) => {
          this.devices[index].name =
            device.name +
            ' | ' +
            device.location.title +
            ' | ' +
            device.tags[0].name
        })
      })
    }

    if (this.currentUserRole === 'ROLE_WATCHER' || this.currentUserRole === 'ROLE_WATCHER_BILLS') {
      ApiService.get(
        'users',
        `?email=${this.currentUserPersonalInfo.email}`,
      ).then(({ data }) => {
        const userLocations = data['hydra:member'][0]['locations']

        ApiService.get(
          'devices',
          `?company=${this.currentUserCompany.replace(
            '/api/v1/companies/',
            '',
          )}&location.id=${userLocations[0].id}&pagination=false`,
        ).then(({ data }) => {
          this.totalItems = data['hydra:member'].length
          this.devices = [...data['hydra:member']]
          this.devices = this.devices.filter(
            (device) =>
              (device.isSaldoReducer !== true) & (device.isDemoDevice !== true),
          )
        })
      })
    }
  },
  methods: {
    generate() {
      if (
        this.selectedDevices.length === 0 ||
        this.range.start === null ||
        this.range.end === null
      ) {
        this.alertMsg = 'Please select device/s & pick range'
        this.showAlert = true

        return
      }

      this.showSpinner = true

      const from = format(this.range.start, "yyyy-MM-dd'T'HH:mm:ss")
      const end = format(this.range.end, "yyyy-MM-dd'T'HH:mm:ss")

      let devicesIds = ''

      this.selectedDevices.forEach((device) => {
        let l = device['@id'].replace('/api/v1/devices/', '')
        devicesIds += l + ','
      })

      this.url = `${process.env.VUE_APP_ROUTE_API_URL}/api/v1/device-finance-report/${devicesIds}/${from}/${end}/${this.currentUserCompanyOib}`

      this.showSpinner = false
      this.isGenerated = true
    },
    last7Days() {
      this.$refs.picker.updateValue({ start: null, end: null })

      this.$refs.picker.updateValue({
        start: add(new Date(), { days: -8 }),
        end: add(new Date(), { days: -1 }),
      })
    },
    last30Days() {
      this.$refs.picker.updateValue({ start: null, end: null })

      this.$refs.picker.updateValue({
        start: add(new Date(), { days: -31 }),
        end: add(new Date(), { days: -1 }),
      })
    },
    thisMonth() {
      this.$refs.picker.updateValue({ start: null, end: null })

      const today = new Date()

      this.$refs.picker.updateValue({
        start: startOfMonth(today),
        end: today,
      })

      this.$refs.picker.move({
        month: getMonth(addMonths(new Date(), -1)) + 2,
        year: getYear(addMonths(new Date(), -1)),
      })
    },
    lastMonth() {
      this.$refs.picker.updateValue({ start: null, end: null })

      this.$refs.picker.updateValue({
        start: startOfMonth(addMonths(new Date(), -1)),
        end: endOfMonth(addMonths(new Date(), -1)),
      })

      this.$refs.picker.move({
        month: getMonth(addMonths(new Date(), -1)) + 1,
        year: getYear(addMonths(new Date(), -1)),
      })
    },
    reset() {
      this.$refs.picker.updateValue({ start: null, end: null })
    },
    resetAll() {
      this.totalItems = null
      this.isGenerated = false
      this.showSpinner = false
      this.isLoaded = false
      this.devices = this.selectedDevices
      this.selectedDevices = []
      this.$refs.picker.updateValue({ start: null, end: null })
    },
    onChangeList: function ({ source, destination }) {
      this.devices = source
      this.selectedDevices = destination
    },
    setStartEndDate() {
      let a = addDays(this.range.start, -1)
      a.setHours(24, 0, 0)
      this.range.start = a

      let b = addDays(this.range.end, 0)
      b.setHours(23, 59, 59)
      this.range.end = b

      this.$refs.picker.updateValue({
        start: this.range.start,
        end: this.range.end,
      })
    },
    generateExport() {
      this.showSpinner = true
      this.isDataGenerate = false
      axios
        .get(this.url)
        .then(({ data }) => {
          if (data.status === 'ok') {
            this.downloadExcelUrl = data.fileUrl
            this.payloadDevices = data.devices
            this.payloadNoTrafficDevices = data.noTrafficDevices
            this.transactions = data.transactions

            this.isDataGenerate = true

            this.$notify({
              group: 'notification',
              type: 'success',
              title: 'Success',
            })
            this.showSpinner = false
          }
        })
        .catch(() => {
          this.$notify({
            group: 'notification',
            type: 'error',
            title: 'Server error',
          })
          this.showSpinner = false
          this.isDataGenerate = false
        })
    },
  },
}
</script>

<style lang="scss">
.card-title {
  display: flex;
  @media only screen and (max-width: 600px) {
    flex-direction: column;
  }
}
</style>
