<template>
  <div class="card card-custom">
    <notifications group="notification" position="top right" />
    <div class="card-body p-0">
      <!--begin: Wizard-->
      <div
        class="wizard wizard-2"
        id="kt_wizard_v2"
        data-wizard-state="step-first"
        data-wizard-clickable="true"
      >
        <!--begin: Wizard Nav -->
        <div class="wizard-nav border-right py-8 px-8 py-lg-20 px-lg-10">
          <div class="wizard-steps">
            <div
              class="wizard-step"
              data-wizard-type="step"
              data-wizard-state="current"
            >
              <div class="wizard-wrapper">
                <div class="wizard-icon">
                  <span class="svg-icon svg-icon-2x">
                    <inline-svg src="media/svg/icons/Media/Airplay.svg" />
                  </span>
                </div>
                <div class="wizard-label">
                  <h3 class="wizard-title">Postavke Uređaja</h3>
                  <div class="wizard-desc">Konfiguracija Loggera & Uređaja</div>
                </div>
              </div>
            </div>
            <div class="wizard-step" data-wizard-type="step">
              <div class="wizard-wrapper">
                <div class="wizard-icon">
                  <span class="svg-icon svg-icon-2x">
                    <inline-svg src="media/svg/icons/Map/Compass.svg" />
                  </span>
                </div>
                <div class="wizard-label">
                  <h3 class="wizard-title">Postavljanje Lokacije</h3>
                  <div class="wizard-desc">Odabir lokacije Uređaja</div>
                </div>
              </div>
            </div>
            <div class="wizard-step" data-wizard-type="step">
              <div class="wizard-wrapper">
                <div class="wizard-icon">
                  <span class="svg-icon svg-icon-2x">
                    <inline-svg
                      src="media/svg/icons/General/Thunder-move.svg"
                    />
                  </span>
                </div>
                <div class="wizard-label">
                  <h3 class="wizard-title">Dodatne Postavke Uređaja</h3>
                  <div class="wizard-desc">Mjesečni trošak najma...</div>
                </div>
              </div>
            </div>
            <div class="wizard-step" data-wizard-type="step">
              <div class="wizard-wrapper">
                <div class="wizard-icon">
                  <span class="svg-icon svg-icon-2x">
                    <inline-svg src="media/svg/icons/General/Settings-2.svg" />
                  </span>
                </div>
                <div class="wizard-label">
                  <h3 class="wizard-title">Konfiguracija Postavke Uređaja</h3>
                  <div class="wizard-desc">Konfiguracija uredaja...</div>
                </div>
              </div>
            </div>
            <div class="wizard-step" data-wizard-type="step">
              <div class="wizard-wrapper">
                <div class="wizard-icon">
                  <span class="svg-icon svg-icon-2x">
                    <inline-svg src="media/svg/icons/General/Like.svg" />
                  </span>
                </div>
                <div class="wizard-label">
                  <h3 class="wizard-title">Pregled Unosa!</h3>
                  <div class="wizard-desc">Review and Submit</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--end: Wizard Nav -->

        <!--begin: Wizard Body -->
        <div class="wizard-body py-8 px-8 py-lg-20 px-lg-10">
          <!--begin: Wizard Form-->
          <div class="row">
            <div class="offset-xxl-2 col-xxl-8">
              <!--begin: Wizard Step 1-->
              <div
                class="pb-5"
                data-wizard-type="step-content"
                data-wizard-state="current"
              >
                <h4 class="mb-10 font-weight-bold text-dark">
                  Postavke Uređaja
                </h4>
                <div class="form-group">
                  <label>Logger</label>
                  <vSelect
                    v-model="device.logger"
                    class="form-control form-control-solid form-control--logger-select"
                    placeholder="Odaberi Logger"
                    :options="loggers"
                    @input="onLoggerSelect"
                    :reduce="(logger) => logger['@id']"
                    label="imei"
                  />
                </div>
                <div class="form-group">
                  <label>Ime</label>
                  <input
                    v-model="device.name"
                    class="form-control form-control-solid"
                  />
                </div>
                <div class="form-group" v-if="company">
                  <label>Tvrtka</label>
                  <input
                    type="text"
                    disabled="disabled"
                    class="form-control form-control-solid"
                    :value="company.name"
                    :placeholder="company.name"
                  />
                </div>
              </div>
              <!--end: Wizard Step 1-->

              <!--begin: Wizard Step 2-->
              <div class="pb-5" data-wizard-type="step-content">
                <h4 class="mb-10 font-weight-bold text-dark">
                  Postavljanje Lokacije Uređaja
                </h4>
                <div class="form-group">
                  <label>Lokacija</label>
                  <vSelect
                    v-model="device.location"
                    :options="locations"
                    :reduce="(location) => location['@id']"
                    label="title"
                    class="form-control form-control-solid form-control--location-select"
                  >
                    <template #option="{ title, city }">
                      <div style="display: flex; align-items: baseline">
                        <span>{{ title }}</span>
                        <span
                          style="margin-left: 0.5rem"
                          class="label label-lg label-light-primary label-inline"
                          >{{ city.name }}</span
                        >
                      </div>
                    </template>
                    <template #selected-option="{ title, city }">
                      <div style="display: flex; align-items: baseline">
                        <span>{{ title }}</span>
                        <span
                          style="margin-left: 0.5rem"
                          class="label label-lg label-light-primary label-inline"
                          >{{ city.name }}</span
                        >
                      </div>
                    </template>
                  </vSelect>
                </div>

                <div class="form-group">
                  <label>Tagovi</label>
                  <vSelect
                    multiple
                    v-model="device.tags"
                    class="form-control form-control-solid form-control--location-select"
                    placeholder="Odaberite tagove"
                    :options="tags"
                    :reduce="(tag) => tag['@id']"
                    label="name"
                  />
                </div>
                <b-button
                  @click="showModal"
                  class="btn btn-light-primary font-weight-bold btn-block mt-4"
                  >Dodaj Tag</b-button
                >
                <b-modal id="add-tag-modal" centered hide-header hide-footer>
                  <template>
                    <div class="form-group mb-2">
                      <b-button
                        class="btn btn-light-primary mb-3"
                        block
                        @click="$bvModal.hide('add-tag-modal')"
                        >Close</b-button
                      >
                      <div class="input-group">
                        <input
                          id="tagName"
                          type="text"
                          v-model="newTag"
                          placeholder="Tag name"
                          class="form-control"
                        />
                      </div>
                    </div>
                    <button
                      @click="addTag"
                      class="btn btn-light-primary font-weight-bold btn-block mt-4"
                    >
                      Add
                    </button>
                  </template>
                </b-modal>
              </div>
              <!--end: Wizard Step 2-->

              <!--begin: Wizard Step 3-->
              <div class="pb-5" data-wizard-type="step-content">
                <h4 class="mb-10 font-weight-bold text-dark">
                  Dodatne Postavke Uređaja
                </h4>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label>Is Demo Device</label>
                      <VtSwitch
                        @checkedEvent="toggleIsDemoDevice(device.isDemoDevice)"
                        :is-checked="device.isDemoDevice"
                        classes="switch-md switch-icon switch-outline switch-primary"
                        name="deviceIsDemoDevice"
                      />
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="form-group">
                      <label>Active</label>
                      <VtSwitch
                        @checkedEvent="toggleIsActive(device.isActive)"
                        :is-checked="device.isActive"
                        classes="switch-md switch-icon switch-outline switch-primary"
                        name="deviceServiceMode"
                      />
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="form-group">
                      <label>Servisni mod</label>
                      <VtSwitch
                        @checkedEvent="toggleServiceMode(device.serviceMode)"
                        :is-checked="device.serviceMode"
                        classes="switch-md switch-icon switch-outline switch-primary"
                        name="deviceServiceMode"
                      />
                    </div>
                  </div>
                  <div
                    v-if="
                      this.currentUserCompanyOib === '05959244936' &&
                      currentUserRole === 'ROLE_ADMIN'
                    "
                    class="col-md-12"
                  >
                    <div class="form-group">
                      <label>Fiscal</label>
                      <VtSwitch
                        @checkedEvent="toggleFiscal(device.fiscal)"
                        :is-checked="device.fiscal"
                        classes="switch-md switch-icon switch-outline switch-primary"
                        name="deviceFiscal"
                      />
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="form-group">
                      <label>Uredaj ima "markicu"</label>
                      <VtSwitch
                        @checkedEvent="toggleHaveMark(device.haveMark)"
                        :is-checked="device.haveMark"
                        classes="switch-md switch-icon switch-outline switch-primary"
                        name="deviceHaveMark"
                      />
                    </div>
                  </div>
                  <div
                    v-if="
                      currentUserRole === 'ROLE_SUPER_ADMIN' ||
                      currentUserRole === 'ROLE_ROOT' ||
                      currentUserRole === 'ROLE_ADMIN'
                    "
                    class="col-md-12"
                  >
                    <div class="form-group">
                      <label>Acceptor</label>
                      <VtSwitch
                        @checkedEvent="toggleIsAcceptor(device.isAcceptor)"
                        :is-checked="device.isAcceptor"
                        classes="switch-md switch-icon switch-outline switch-primary"
                        name="deviceIsAcceptor"
                      />
                    </div>
                  </div>
                  <div class="col-md-12" v-if="device.isAcceptor">
                    <div class="form-group">
                      <label>Saldo Reducer</label>
                      <VtSwitch
                        @checkedEvent="
                          toggleIsSaldoReducer(device.isSaldoReducer)
                        "
                        :is-checked="device.isSaldoReducer"
                        classes="switch-md switch-icon switch-outline switch-primary"
                        name="deviceIsSaldoReducer"
                      />
                    </div>
                  </div>
                  <div class="col-md-12" v-if="device.isSaldoReducer">
                    <div class="form-group">
                      <label>Saldo</label>
                      <input
                        v-model="device.saldo"
                        type="text"
                        class="form-control form-control-solid form-control-lg"
                        placeholder="Saldo"
                      />
                    </div>
                  </div>
                  <div
                    class="col-md-12"
                    v-if="
                      this.currentUserPersonalInfo.email.includes(
                        '@kidsdelux.hr'
                      ) && this.currentUserRole === 'ROLE_ADMIN'
                    "
                  >
                    <div class="form-group">
                      <label>{{ $t('GENERAL.TOKEN_DROP_DEVICE') }}</label>
                      <VtSwitch
                        @checkedEvent="
                          toggleIsMonsterDevice(device.isMonsterDevice)
                        "
                        :is-checked="device.isMonsterDevice"
                        classes="switch-md switch-icon switch-outline switch-primary"
                        name="deviceIsMonsterDevice"
                      />
                    </div>
                  </div>
                  <div
                    v-if="
                      this.currentUserPersonalInfo.email.includes(
                        '@kidsdelux.hr'
                      ) && this.currentUserRole === 'ROLE_ADMIN'
                    "
                    class="col-md-12"
                  >
                    <div class="form-group">
                      <label>Prize</label>
                      <VtSwitch
                        @checkedEvent="
                          toggleIsPrizeDevice(device.isPrizeDevice)
                        "
                        :is-checked="device.isPrizeDevice"
                        classes="switch-md switch-icon switch-outline switch-primary"
                        name="deviceIsPrizeDevice"
                      />
                    </div>
                  </div>
                  <div
                    v-if="
                      this.currentUserPersonalInfo.email.includes(
                        '@kidsdelux.hr'
                      ) &&
                      this.currentUserRole === 'ROLE_ADMIN' &&
                      device.isPrizeDevice
                    "
                    class="col-md-6"
                  >
                    <div class="form-group">
                      <label>Šifra nagrade</label>
                      <input
                        v-model="device.prizeMeta.code"
                        type="text"
                        class="form-control form-control-solid form-control-lg"
                        placeholder="Šifra nagrade"
                      />
                    </div>
                  </div>
                  <div
                    class="col-md-6"
                    v-if="
                      this.currentUserPersonalInfo.email.includes(
                        '@kidsdelux.hr'
                      ) &&
                      this.currentUserRole === 'ROLE_ADMIN' &&
                      device.isPrizeDevice
                    "
                  >
                    <div class="form-group">
                      <label>Naziv nagrade</label>
                      <input
                        v-model="device.prizeMeta.name"
                        type="text"
                        class="form-control form-control-solid form-control-lg"
                        placeholder="Naziv nagrade"
                      />
                    </div>
                  </div>
                  <div
                    class="col-md-6"
                    v-if="
                      this.currentUserPersonalInfo.email.includes(
                        '@kidsdelux.hr'
                      ) &&
                      this.currentUserRole === 'ROLE_ADMIN' &&
                      device.isPrizeDevice
                    "
                  >
                    <div class="form-group">
                      <label>Cijena nagrade</label>
                      <input
                        v-model="device.prizeMeta.price"
                        type="number"
                        class="form-control form-control-solid form-control-lg"
                        placeholder="Cijena nagrade"
                      />
                    </div>
                  </div>
                  <div
                    class="col-md-6"
                    v-if="
                      this.currentUserPersonalInfo.email.includes(
                        '@kidsdelux.hr'
                      ) &&
                      this.currentUserRole === 'ROLE_ADMIN' &&
                      device.isPrizeDevice
                    "
                  >
                    <div class="form-group">
                      <label>Frekvencija nagrade (u eurima)</label>
                      <input
                        v-model="device.prizeMeta.frequencyInEuros"
                        type="number"
                        class="form-control form-control-solid form-control-lg"
                        placeholder="Iznos eura za svaku dodijeljenu nagradu"
                      />
                    </div>
                  </div>
                </div>
                <div
                  class="row"
                  v-if="
                    this.currentUserPersonalInfo.email.includes(
                      '@kidsdelux.hr'
                    ) && this.currentUserRole === 'ROLE_ADMIN'
                  "
                >
                  <div
                    class="row"
                    v-if="
                      currentUserRole === 'ROLE_ROOT' ||
                      currentUserRole === 'ROLE_SUPER_ADMIN' ||
                      currentUserRole === 'ROLE_ADMIN' ||
                      currentUserRole === 'ROLE_WORKER' ||
                      currentUserRole === 'ROLE_JUNIOR'
                    "
                  >
                    <div class="col-md-12">
                      <div class="form-group">
                        <label>Token</label>
                        <input
                          v-model="device.token"
                          type="number"
                          class="form-control form-control-solid form-control-lg"
                          placeholder="Token"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="row"
                  v-if="
                    currentUserRole === 'ROLE_SUPER_ADMIN' ||
                    currentUserRole === 'ROLE_ROOT' ||
                    currentUserRole === 'ROLE_ADMIN'
                  "
                >
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>Saldo</label>
                      <input
                        v-model="device.saldo"
                        type="number"
                        class="form-control form-control-solid form-control-lg"
                        placeholder="Saldo"
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>Saldo Minimum</label>
                      <input
                        v-model="device.saldoMinimum"
                        type="number"
                        class="form-control form-control-solid form-control-lg"
                        placeholder="Saldo minimum"
                      />
                    </div>
                  </div>
                </div>
                <div
                  class="row"
                  v-if="
                    (currentUserRole === 'ROLE_SUPER_ADMIN' ||
                      currentUserRole === 'ROLE_ROOT' ||
                      currentUserRole === 'ROLE_ADMIN') &&
                    device.isMonsterDevice
                  "
                >
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>Token Minimum</label>
                      <input
                        v-model="device.tokenMinimum"
                        type="number"
                        class="form-control form-control-solid form-control-lg"
                        placeholder="Token minimum"
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>Token</label>
                      <input
                        v-model="device.token"
                        type="number"
                        class="form-control form-control-solid form-control-lg"
                        placeholder="Token"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>ISU broj</label>
                      <input
                        v-model="device.isuNumber"
                        type="text"
                        class="form-control form-control-solid form-control-lg"
                        placeholder="ISU broj"
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>Oznaka PP</label>
                      <input
                        v-model="device.businessSpaceLabel"
                        type="text"
                        class="form-control form-control-solid form-control-lg"
                        placeholder="Oznaka poslovnog prostora"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label>Inventurni broj</label>
                      <input
                        v-model="device.inventoryNumber"
                        type="text"
                        class="form-control form-control-solid form-control-lg"
                        name="locstate"
                        placeholder="Inventurni broj"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label>Machine Id</label>
                      <input
                        v-model="device.machineId"
                        type="text"
                        class="form-control form-control-solid form-control-lg"
                        placeholder="Machine ID"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <!--end: Wizard Step 3-->

              <!--begin: Wizard Step 4-->
              <div class="pb-5" data-wizard-type="step-content">
                <h4 class="mb-10 font-weight-bold text-dark">
                  Konfiguracija Uređaja
                </h4>
                <div class="row">
                  <div class="col-12">
                    <div class="form-group">
                      <label>Is Converted to Euro (€)</label>
                      <VtSwitch
                        @checkedEvent="
                          toggleIsConvertedToEuro(device.isConvertedToEuro)
                        "
                        :is-checked="device.isConvertedToEuro"
                        classes="switch-md switch-icon switch-outline switch-primary"
                        name="deviceIsConvertedToEuro"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <vue-json-editor
                        v-model="deviceConfiguration"
                        :show-btns="false"
                        mode="code"
                        :expandedOnStart="true"
                      ></vue-json-editor>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <button
                        @click="saveDeviceConfiguration"
                        class="btn btn-primary"
                      >
                        Pospremi konfiguraciju
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <!--end: Wizard Step 4-->

              <!--begin: Wizard Step 5-->
              <div class="pb-5" data-wizard-type="step-content">
                <h4 class="mb-10 font-weight-bold text-dark">
                  Pregled svih unesenih polja Uređaja
                </h4>
                <div class="border-bottom mb-5 pb-5">
                  <div class="font-weight-bold mb-3">Postavke Uređaja:</div>
                  <div class="line-height-md">
                    Logger: {{ device.logger }}
                    <br />
                    Ime: {{ device.name }} <br />
                    Tvrtka: {{ device.company }}
                  </div>
                </div>
              </div>
              <!--end: Wizard Step 5-->

              <!--begin: Wizard Actions -->
              <div class="d-flex justify-content-between border-top pt-10">
                <div class="mr-2">
                  <button
                    class="btn btn-light-primary font-weight-bold text-uppercase px-9 py-4"
                    data-wizard-type="action-prev"
                  >
                    Nazad
                  </button>
                </div>
                <div>
                  <button
                    v-on:click="submit"
                    class="btn btn-success font-weight-bold text-uppercase px-9 py-4"
                    data-wizard-type="action-submit"
                  >
                    Spremi
                  </button>
                  <button
                    class="btn btn-primary font-weight-bold text-uppercase px-9 py-4"
                    data-wizard-type="action-next"
                  >
                    Sljedeći korak
                  </button>
                </div>
              </div>
              <!--end: Wizard Actions -->
            </div>
            <!--end: Wizard-->
          </div>
        </div>
        <!--end: Wizard Body -->
      </div>
      <!--end: Wizard-->
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import KTUtil from '@/assets/js/components/util'
import KTWizard from '@/assets/js/components/wizard'
import vSelect from 'vue-select'
import ApiService from '@/core/services/api.service'
import ProfileService from '@/core/services/profile.service'
import { mapGetters } from 'vuex'
import VtSwitch from '@/view/content/components/switch/VtSwitch'
import vueJsonEditor from 'vue-json-editor'

export default {
  name: 'DevicesNew',
  components: {
    vSelect,
    VtSwitch,
    vueJsonEditor,
  },
  data() {
    return {
      device: {
        name: null,
        isDemoDevice: false,
        isActive: true,
        isAcceptor: false,
        isSaldoReducer: false,
        serviceMode: false,
        fiscal: true,
        haveMark: false,
        logger: null,
        saldo: '0.00',
        isuNumber: null,
        businessSpaceLabel: null,
        inventoryNumber: null,
        location: null,
        company: null,
        tags: [],
        articles: [],
        billNumber: 1,
        saldoMinimum: 0,
        tokenMinimum: 0,
        token: 0,
        isProblematic: false,
        isCritical: false,
        sendProblematicEmailNotifications: true,
        sendCriticalEmailNotifications: true,
        eventMeta: {
          lastEmpty: '',
          lastPaySms: '',
          lastPayCoin: '',
          lastPayRfCard: '',
        },
        dailySaldo: {
          sms: '0.00',
          coin: '0.00',
          rfCard: '0.00',
        },
        isConvertedToEuro: true,
        isPrizeDevice: false,
        isMonsterDevice: false,
        prizeMeta: {
          code: '',
          name: '',
          price: 0,
          frequencyInEuros: 0,
        },
        machineNumber: '',
      },
      loggers: [],
      locations: [],
      tags: [],
      deviceConfiguration: {
        imei: null,
        configuration: null,
      },
      company: null,
      newTag: '',
    }
  },
  computed: {
    ...mapGetters([
      'currentUserRole',
      'currentUserCompany',
      'currentUserCompanyModule',
      'currentUserPersonalInfo',
      'currentIsTopGun',
      'currentUserCompanyOib',
    ]),
    codemirror() {
      return this.$refs.cmEditor.codemirror
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Uređaji', route: '/devices' },
      { title: 'Novi Uređaj' },
    ])

    if (
      this.currentUserRole === 'ROLE_ROOT' ||
      this.currentUserRole === 'ROLE_SUPER_ADMIN'
    ) {
      this.fetchAllLoggers()
      ApiService.get('locations', '?pagination=false').then(({ data }) => {
        this.locations = [...data['hydra:member']]
      })
      ApiService.get('tags', '?pagination=false').then(({ data }) => {
        this.tags = [...data['hydra:member']]
      })
    }

    if (this.currentUserRole === 'ROLE_ADMIN') {
      this.fetchCompanyLoggers()
      ApiService.get(
        'loggers',
        '?pagination=false&company=' + this.currentUserCompany
      ).then(({ data }) => {
        data['hydra:member'].forEach((logger) => {
          if (!logger.haveDevice) this.loggers.push(logger)
        })
      })
      ApiService.get(
        'locations',
        `?company=${this.currentUserCompany}&pagination=false`
      ).then(({ data }) => {
        this.locations = [...data['hydra:member']]
      })
      ApiService.get('tags', '?pagination=false').then(({ data }) => {
        this.tags = [...data['hydra:member']]
      })
    }

    ApiService.get(
      ProfileService.getProfileAccountInfo().company_api_endpoint.replace(
        '/api/v1/',
        ''
      )
    ).then(({ data }) => {
      this.company = data
      this.device.company = this.company['@id']
    })

    const wizard = new KTWizard('kt_wizard_v2', {
      startStep: 1,
      clickableSteps: true,
    })

    // Change event
    wizard.on('change', function (/*wizardObj*/) {
      setTimeout(() => {
        KTUtil.scrollTop()
      }, 500)
    })
  },
  methods: {
    async fetchAllLoggers() {
      let allLoggers = []
      let currentPage = 1
      let totalPages = 1

      const fetchPage = (page) => {
        return ApiService.get(
          'loggers',
          `?select=id,haveDevice&pagination=true&itemsPerPage=100&page=${page}`
        ).then(({ data }) => {
          allLoggers = [...allLoggers, ...data['hydra:member']]
          data['hydra:member'].forEach((logger) => {
            if (!logger.haveDevice) this.loggers.push(logger)
          })
          totalPages = data['hydra:totalItems'] / 100 || 1
          return totalPages
        })
      }

      while (currentPage <= totalPages) {
        await fetchPage(currentPage)
        currentPage += 1
      }

      this.loggers = allLoggers
    },

    async fetchCompanyLoggers() {
      let allLoggers = []
      let currentPage = 1
      let totalPages = 1

      const fetchPage = (page) => {
        return ApiService.get(
          'loggers',
          `?company=${this.currentUserCompany}&select=id,haveDevice&pagination=true&itemsPerPage=100&page=${page}`
        ).then(({ data }) => {
          allLoggers = [...allLoggers, ...data['hydra:member']]
          data['hydra:member'].forEach((logger) => {
            if (!logger.haveDevice) this.loggers.push(logger)
          })
          totalPages = data['hydra:totalItems'] / 100 || 1
          return totalPages
        })
      }

      while (currentPage <= totalPages) {
        await fetchPage(currentPage)
        currentPage += 1 // Move to the next page
      }

      this.loggers = allLoggers // After all pages are fetched, update the loggers
    },
    onLoggerSelect(val) {
      ApiService.get(val.replace('/api/v1', '')).then(({ data }) => {
        this.deviceConfiguration.imei = data.imei
      })
    },
    toggleIsDemoDevice(checked) {
      if (checked) {
        let confirmation = confirm('Želite da ovaj uredaj ne bude demo?')
        if (confirmation === false) return
      } else {
        let confirmation = confirm('Želite da ovaj uredaj bude demo?')
        if (confirmation === false) return
      }

      this.device.isDemoDevice = !checked
    },
    toggleIsPrizeDevice(checked) {
      if (checked) {
        let confirmation = confirm('Želite da ovaj uredaj ne bude prize?')
        if (confirmation === false) return
      } else {
        let confirmation = confirm('Želite da ovaj uredaj bude prize?')
        if (confirmation === false) return
      }

      this.device.isPrizeDevice = !checked
    },

    toggleIsMonsterDevice(checked) {
      if (checked) {
        let confirmation = confirm('Želite da ovaj uredaj ne bude monster?')
        if (confirmation === false) return
      } else {
        let confirmation = confirm('Želite da ovaj uredaj bude monster?')
        if (confirmation === false) return
      }

      this.device.isMonsterDevice = !checked
    },

    toggleIsConvertedToEuro(checked) {
      this.device.isConvertedToEuro = !checked
    },

    toggleIsActive(checked) {
      if (checked) {
        let confirmation = confirm('Želite deaktivirati ovaj uređaj?')
        if (confirmation === false) return
      } else {
        let confirmation = confirm('Želite aktivirati ovaj uređaj?')
        if (confirmation === false) return
      }

      this.device.isActive = !checked
    },

    toggleServiceMode(checked) {
      this.device.serviceMode = !checked
    },

    toggleFiscal(checked) {
      this.device.fiscal = !checked
    },

    toggleHaveMark(checked) {
      this.device.haveMark = !checked
    },

    toggleIsAcceptor(checked) {
      this.device.isAcceptor = !checked
    },

    toggleIsSaldoReducer(checked) {
      this.device.isSaldoReducer = !checked
    },

    //
    saveDeviceConfiguration() {
      ApiService.post('device_configurations', this.deviceConfiguration).then(
        () => {
          this.$notify({
            group: 'notification',
            type: 'success',
            title: 'Konfiguracija ucitana',
          })
        }
      )
    },

    // show modal callback
    showModal() {
      this.$bvModal.show('add-tag-modal')
      setTimeout(() => {
        document.getElementById('tagName').focus()
      }, 0)
    },

    // region
    addTag() {
      const companyId = this.device.company.replace('/api/v1/companies/', '')
      ApiService.post('/tags', {
        name: this.newTag,
        company: this.device.company,
      }).then(({ data }) => {
        this.device.tags.push(data)
        this.$bvModal.hide('add-tag-modal')
        this.newTag = ''

        ApiService.get('tags', `?company=${companyId}`).then(({ data }) => {
          this.tags = [...data['hydra:member']]
          this.$notify({
            group: 'notification',
            type: 'success',
            title: 'Tag uspješno dodan',
          })
        })
      })
    },

    //
    submit: function (e) {
      e.preventDefault()

      if (!this.device.logger) delete this.device.logger

      this.device.saldoMinimum = parseFloat(this.device.saldoMinimum)
      this.device.tokenMinimum = parseFloat(this.device.tokenMinimum)
      this.device.token = parseFloat(this.device.token)

      ApiService.post('devices', this.device)
        .then(() => {
          setTimeout(() => {
            this.$router.push({ name: 'devices-list' })
          }, 200)
        })
        .catch(() => {})
    },
  },
}
</script>

<style lang="scss">
@import '@/assets/sass/pages/wizard/wizard-2.scss';
@import 'vue-select/src/scss/vue-select.scss';

.form-control {
  &--logger-select,
  &--location-select {
    height: unset !important;

    .vs__dropdown-toggle {
      padding: 0;
      border: 0;
      border-radius: 0;
    }
    .vs__dropdown-menu {
      border: 1px solid #e4e6ef;
      -webkit-box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);
      box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);

      /* width */
      &::-webkit-scrollbar {
        width: 4px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: #f1f1f1;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #888;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #555;
      }

      li {
        padding: 0.65rem 1rem;

        &:hover {
          background: #f3f6f9;
          color: #3f4254;
        }
      }
    }

    .vs__open-indicator {
    }
    .vs__dropdown-toggle {
    }
    .vs__clear {
      display: none;
    }
    .vs__selected {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      padding: 0.25rem 0.75rem;
      font-size: 1rem;
      margin: 0.1rem 0.4rem 0.1rem 0;
      position: relative;
      float: left;
      color: #3f4254;
      background: #f3f6f9;
      border: 0;
      border-radius: 0.42rem;
    }
    .vs__no-options {
    }
    .vs__spinner {
    }
    .vs__deselect {
    }
    .vs__active {
    }
    .vs__dropdown-option--highlight {
      background: #ebedf3;
      color: #3f4254;
    }
  }
}

.jsoneditor-modes,
.jsoneditor-poweredBy {
  display: none !important;
}
.ace_content {
  height: 350px !important;
}
</style>
