<template>
  <div>
    <b-alert :show="showAlert" variant="danger">
      <span>{{ alertMsg }}</span>
    </b-alert>
    <div class="row">
      <div class="col-md-9">
        <div class="card card-custom gutter-b">
          <div class="card-body px-2">
            <DualListBoxDevices
              :source="devices"
              :destination="selectedDevices"
              label="name"
              @onChangeList="onChangeList"
            />
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="card card-custom gutter-b">
          <div class="card-body">
            <DatePicker
              ref="picker"
              v-model="range"
              mode="dateTime"
              :attributes="attributes"
              :masks="masks"
              locale="hr"
              is-range
              is24hr
              is-expanded
              :columns="$screens({ default: 1, lg: 1 })"
              :max-date="new Date()"
            />
            <button
              @click="last7Days"
              class="btn btn-light-primary btn-block font-weight-bold mt-2"
            >
              Last 7 days
            </button>
            <button
              @click="last30Days"
              class="btn btn-light-primary btn-block font-weight-bold"
            >
              Last 30 days
            </button>
            <button
              @click="thisMonth"
              class="btn btn-light-primary btn-block font-weight-bold"
            >
              This month
            </button>
            <button
              @click="lastMonth"
              class="btn btn-light-primary btn-block font-weight-bold"
            >
              Last month
            </button>
            <button
              @click="reset"
              class="btn btn-light-danger btn-block font-weight-bold"
            >
              Reset
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="row mb-5">
      <div class="col-12">
        <div class="pt-4">
          <button
            class="btn btn-primary btn-block font-weight-bold"
            @click="generate"
          >
            Generate
          </button>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 text-center">
        <b-spinner
          v-if="showSpinner"
          variant="primary"
          label="Spinning"
        ></b-spinner>
      </div>
    </div>
    <div v-if="isGenerated" class="row mb-6">
      <div class="col-md-12">
        <div class="card card-custom gutter-b">
          <div class="card-header border-0 pb-0">
            <h3 class="card-title font-weight-bold">Devices Traffic</h3>
          </div>
          <div class="card-body p-0">
            <LocationsTrafficRefactored
              v-if="isGenerated"
              :traffic="generatedResults"
              :selected="selectedDevices"
              :range="range"
            />
          </div>
        </div>
      </div>
    </div>
    <div v-if="isStatisticTrafficGenerated" class="row mb-6">
      <div class="col-md-6">
        <div class="card card-custom gutter-b">
          <div class="card-header border-0 pb-0">
            <h3 class="card-title font-weight-bold">
              Average Weekdays Traffic
            </h3>
          </div>
          <div class="card-body p-0">
            <WeekDaysTrafficRefactored
              v-if="
                isStatisticTrafficGenerated &&
                currentActiveModuleComponents?.reports.AverageByDaysInWeek
              "
              :chartData="averageWeekdaysTraffic"
              :range="range"
            />
          </div>
        </div>
      </div>
      <!-- MonthTraffic Graph -->
      <div class="col-md-6">
        <div class="card card-custom gutter-b">
          <div class="card-header border-0 pb-0">
            <h3 class="card-title font-weight-bold">Monthly Traffic</h3>
          </div>
          <div class="card-body p-0">
            <MonthTrafficRefactored
              v-if="
                isStatisticTrafficGenerated &&
                currentActiveModuleComponents?.reports.reportMonth
              "
              :chartData="monthlyTraffic"
              :range="range"
            />
          </div>
        </div>
      </div>
    </div>
    <div v-if="isStatisticTrafficGenerated" class="row mb-6">
      <div class="col-md-6">
        <div class="card card-custom gutter-b">
          <div class="card-header border-0 pb-0">
            <h3 class="card-title font-weight-bold">Weekly Traffic</h3>
          </div>
          <div class="card-body p-0">
            <WeekTrafficRefactored
              v-if="
                isStatisticTrafficGenerated &&
                currentActiveModuleComponents?.reports.reportWeek
              "
              :chartData="weeklyTraffic"
              :range="range"
            />
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="card card-custom gutter-b">
          <div class="card-header border-0 pb-0">
            <h3 class="card-title font-weight-bold">Yearly Traffic</h3>
          </div>
          <div class="card-body p-0">
            <YearTrafficRefactored
              v-if="
                isStatisticTrafficGenerated &&
                currentActiveModuleComponents?.reports.reportYear
              "
              :chartData="yearlyTraffic"
              :range="range"
            />
          </div>
        </div>
      </div>
    </div>
    <div v-if="isStatisticTrafficGenerated" class="row mb-6">
      <div class="col-md-12 pb-6">
        <div class="card card-custom gutter-b pb-6">
          <div class="card-header border-0 pb-0 pb-6">
            <h3 class="card-title font-weight-bold">Days Traffic</h3>
          </div>
          <div class="card-body p-0">
            <DaysTrafficRefactored
              v-if="
                isStatisticTrafficGenerated &&
                currentActiveModuleComponents?.reports.reportByDaysInRange
              "
              :type="'device'"
              :chartData="daysTraffic"
              :range="range"
            />
          </div>
        </div>
      </div>
    </div>
    <div v-if="isStatisticTrafficGenerated" class="row mb-6">
      <div class="col-md-12">
        <div class="card card-custom gutter-b">
          <div class="card-header border-0 pb-0">
            <h3 class="card-title font-weight-bold">Average Hourly Traffic</h3>
          </div>
          <div class="card-body p-0">
            <HoursTrafficRefactored
              v-if="
                isStatisticTrafficGenerated &&
                currentActiveModuleComponents?.reports.AverageByHoursOfTheDay
              "
              :chartData="hoursTraffic"
              :range="range"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import DatePicker from 'v-calendar/lib/components/date-picker.umd'
import DualListBoxDevices from '@/view/content/components/dual-list-box/DualListBoxDevices'
import ApiService from '@/core/services/api.service'
import format from 'date-fns/format'
import add from 'date-fns/add'
import WeekTrafficRefactored from '@/view/pages/finance-report/_components/WeekTrafficRefactored'
import HoursTrafficRefactored from '@/view/pages/finance-report/_components/HoursTrafficRefactored'
import WeekDaysTrafficRefactored from '@/view/pages/finance-report/_components/WeekDaysTrafficRefactored'
import DaysTrafficRefactored from '@/view/pages/finance-report/_components/DaysTrafficRefactored'
import startOfMonth from 'date-fns/startOfMonth'
import getMonth from 'date-fns/getMonth'
import addMonths from 'date-fns/addMonths'
import getYear from 'date-fns/getYear'
import endOfMonth from 'date-fns/endOfMonth'
import MonthTrafficRefactored from '@/view/pages/finance-report/_components/MonthTrafficRefactored'
import LocationsTrafficRefactored from '@/view/pages/finance-report/_components/LocationsTrafficRefactored'
import addDays from 'date-fns/addDays'
import YearTrafficRefactored from './_components/YearTrafficRefactored.vue'

export default {
  name: 'DeviceRefactored',
  components: {
    DatePicker,
    DualListBoxDevices,
    WeekDaysTrafficRefactored,
    DaysTrafficRefactored,
    HoursTrafficRefactored,
    WeekTrafficRefactored,
    MonthTrafficRefactored,
    YearTrafficRefactored,
    LocationsTrafficRefactored,
  },
  data() {
    return {
      devices: [],
      selectedDevices: [],
      generatedResults: [],
      resultsDays: [],
      totalItems: null,
      range: {
        start: add(new Date(), { days: -1 }).setHours(24, 0, 0),
        end: new Date().setHours(23, 59, 59),
      },
      masks: {
        input: 'dd.MM.yyyy - HH:mm:ss',
      },
      attributes: [
        {
          key: 'today',
          highlight: true,
          dates: null,
        },
      ],
      showAlert: false,
      alertMsg: null,
      base: 0,
      tax: 0,
      total: 0,
      isGenerated: false,
      showSpinner: false,
      isLoaded: false,
      averageWeekdaysTraffic: [],
      monthlyTraffic: [],
      weeklyTraffic: [],
      daysTraffic: [],
      hoursTraffic: [],
      yearlyTraffic: [],
      isStatisticTrafficGenerated: false,
    }
  },
  computed: {
    ...mapGetters([
      'currentUserRole',
      'currentUserCompany',
      'currentActiveModuleComponents',
      'currentUserPersonalInfo',
    ]),
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Device', route: '/finance-report/location' },
    ])

    if (
      this.currentUserRole === 'ROLE_ROOT' ||
      this.currentUserRole === 'ROLE_SUPER_ADMIN'
    ) {
      ApiService.get(
        'select-device',
        `?companyId=${this.currentUserCompany.replace(
          '/api/v1/companies/',
          ''
        )}`
      ).then(({ data }) => {
        this.totalItems = data.length
        this.devices = data
        this.devices = this.devices.filter(
          (device) =>
            (device.isSaldoReducer !== true) & (device.isDemoDevice !== true)
        )
        this.devices.forEach((device, index) => {
          this.devices[index].name =
            device.name + ' | ' + device.location + ' | ' + device.tag
        })
      })
    }

    if (
      this.currentUserRole === 'ROLE_ADMIN' ||
      this.currentUserRole === 'ROLE_WORKER'
    ) {
      ApiService.get(
        'devices',
        `?company=${this.currentUserCompany.replace(
          '/api/v1/companies/',
          ''
        )}&pagination=false&new=1&select=flags,timestamps`
      ).then(({ data }) => {
        this.totalItems = data.length
        this.devices = [...data['hydra:member']]
        this.devices = this.devices.filter(
          (device) => !device.isSaldoReducer & !device.isDemoDevice
        )
        this.devices.forEach((device, index) => {
          this.devices[index].name =
            device.name + ' | ' + device.locationName + ' | '
        })
      })
    }

    if (
      this.currentUserRole === 'ROLE_WATCHER' ||
      this.currentUserRole === 'ROLE_WATCHER_BILLS'
    ) {
      ApiService.get(
        'users',
        `?email=${this.currentUserPersonalInfo.email}`
      ).then(({ data }) => {
        const userLocation = data['hydra:member'][0]['locations'][0].id

        ApiService.get(
          'select-location-device',
          `?locationId=${userLocation}`
        ).then(({ data }) => {
          this.totalItems = data.length
          this.devices = data
          this.devices = this.devices.filter(
            (device) => !device.isSaldoReducer & !device.isDemoDevice
          )
        })
      })
    }
  },
  methods: {
    generate() {
      if (
        this.selectedDevices.length === 0 ||
        this.range.start === null ||
        this.range.end === null
      ) {
        this.alertMsg = 'Please select device/s & pick range'
        this.showAlert = true

        return
      }

      this.showSpinner = true
      this.isGenerated = false
      this.isStatisticTrafficGenerated = false
      this.generatedResults = []

      const after = format(this.range.start, "yyyy-MM-dd'T'HH:mm:ss")
      const before = format(this.range.end, "yyyy-MM-dd'T'HH:mm:ss")

      let queryDeviceIds = this.selectedDevices.map((device) => device.id)

      ApiService.post(
        `financial-analysis-by-device?after=${after}&before=${before}`,
        { ids: queryDeviceIds }
      ).then(({ data }) => {
        this.generatedResults = data
        this.showSpinner = false
        this.isGenerated = true
      })

      ApiService.post(`statistic-traffic?after=${after}&before=${before}`, {
        ids: queryDeviceIds,
      }).then(({ data }) => {
        const weekdayData = []
        const monthData = []
        const weekData = []
        const dayData = []
        const hourData = []
        const yearData = []
        data.map((item) => {
          const perWeekdayPaymentType = {
            name: item.payment_type,
            data: JSON.parse(item.data.weekdayData),
          }
          weekdayData.push(perWeekdayPaymentType)

          const perMonthPaymentType = {
            name: item.payment_type,
            data: JSON.parse(item.data.monthData),
          }
          monthData.push(perMonthPaymentType)

          const perWeekPaymentType = {
            name: item.payment_type,
            data: JSON.parse(item.data.weekData),
          }
          weekData.push(perWeekPaymentType)

          const perDayPaymentType = {
            name: item.payment_type,
            data: JSON.parse(item.data.dayData),
          }
          dayData.push(perDayPaymentType)

          const perHourPaymentType = {
            name: item.payment_type,
            data: JSON.parse(item.data.hourData),
          }
          hourData.push(perHourPaymentType)

          const perYearPaymentType = {
            name: item.payment_type,
            data: JSON.parse(item.data.yearData),
          }
          yearData.push(perYearPaymentType)
        })
        this.averageWeekdaysTraffic = weekdayData
        this.monthlyTraffic = monthData
        this.weeklyTraffic = weekData
        this.daysTraffic = dayData
        this.hoursTraffic = hourData
        this.yearlyTraffic = yearData
        this.isStatisticTrafficGenerated = true
        this.showSpinner = false
      })
    },
    last7Days() {
      this.$refs.picker.updateValue({ start: null, end: null })

      this.$refs.picker.updateValue({
        start: add(new Date(), { days: -8 }).setHours(24, 0, 0),
        end: add(new Date(), { days: -1 }).setHours(23, 59, 59),
      })
    },
    last30Days() {
      this.$refs.picker.updateValue({ start: null, end: null })

      this.$refs.picker.updateValue({
        start: add(new Date(), { days: -31 }).setHours(24, 0, 0),
        end: add(new Date(), { days: -1 }).setHours(23, 59, 59),
      })
    },
    thisMonth() {
      this.$refs.picker.updateValue({ start: null, end: null })

      const today = new Date()

      this.$refs.picker.updateValue({
        start: startOfMonth(today),
        end: today,
      })

      this.$refs.picker.move({
        month: getMonth(addMonths(new Date(), -1)) + 2,
        year: getYear(addMonths(new Date(), -1)),
      })
    },
    lastMonth() {
      this.$refs.picker.updateValue({ start: null, end: null })

      this.$refs.picker.updateValue({
        start: startOfMonth(addMonths(new Date(), -1)),
        end: endOfMonth(addMonths(new Date(), -1)),
      })

      this.$refs.picker.move({
        month: getMonth(addMonths(new Date(), -1)) + 1,
        year: getYear(addMonths(new Date(), -1)),
      })
    },
    reset() {
      // this.locations.push(this.selectedLocations)
      // this.selectedLocations = []

      this.$refs.picker.updateValue({ start: null, end: null })
      // this.range.start = null
      // this.range.end = null
    },
    resetAll() {
      this.generatedResults = []
      this.resultsDays = []
      this.totalItems = null
      this.range.start = null
      this.range.end = null
      this.base = 0
      this.tax = 0
      this.total = 0
      this.isGenerated = false
      this.showSpinner = false
      this.isLoaded = false
      this.averageWeekdaysTraffic = []
      this.monthlyTraffic = []
      this.weeklyTraffic = []
      this.daysTraffic = []
      this.hoursTraffic = []
      this.yearlyTraffic = []
      this.isStatisticTrafficGenerated = false
    },
    onChangeList: function ({ source, destination }) {
      this.devices = source
      this.selectedDevices = destination
    },
    setStartEndDate() {
      let a = addDays(this.range.start, -1)
      a.setHours(24, 0, 0)
      this.range.start = a

      let b = addDays(this.range.end, 0)
      b.setHours(23, 59, 59)
      this.range.end = b

      this.$refs.picker.updateValue({
        start: this.range.start,
        end: this.range.end,
      })
    },
  },
}
</script>

<style lang="scss">
.card-title {
  display: flex;
  @media only screen and (max-width: 600px) {
    flex-direction: column;
  }
}
</style>
