<template>
  <div>
    <div
      class="card card-custom gutter-b"
      style="width: 100%; height: 60vh; overflow-y: auto"
    >
      <div class="card-header border-0 py-5">
        <h3 class="card-title font-weight-bolder text-dark">
          <span>Average report by days in week:</span>
          <span>
            <span class="ml-5"
              ><span
                class="label label-lg label-inline label-light-primary font-weight-bold"
                >{{ range.start | formatDate }}</span
              ></span
            >
            <span
              ><span class="flex-shrink-0 my-2 mx-3"
                ><i class="la la-arrow-right"></i></span
            ></span>
            <span
              ><span
                class="label label-lg label-inline label-light-primary font-weight-bold"
                >{{ range.end | formatDate }}</span
              ></span
            >
          </span>
        </h3>
      </div>
      <div class="card-body pt-0" style="display: flex; align-items: center">
        <VueApexCharts
          v-if="isReady"
          :options="options"
          :series="series"
          height="100%"
          width="100%"
          style="height: 100%; width: 100%"
        />
      </div>
    </div>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import { dateFilter } from 'vue-date-fns'
import { mapGetters } from 'vuex'

export default {
  name: 'WeekDaysTrafficRefactored',
  filters: {
    date: dateFilter,
  },
  components: {
    VueApexCharts,
  },
  props: {
    chartData: {
      type: Array,
    },
    range: {
      type: Object,
    },
  },
  data() {
    return {
      options: {
        chart: {
          type: 'bar',
          height: 350,
          stacked: true,
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
        },
        plotOptions: {
          bar: {
            borderRadius: 8,
          },
        },
        dataLabels: {
          enabled: true,
          offsetX: -2,
          offsetY: 7,
          formatter(val) {
            return (
              val
                .toFixed(2)
                .replace('.', ',')
                .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') + ' €'
            )
          },
        },
        xaxis: {
          categories: [
            'Monday',
            'Tuesday',
            'Wednesday',
            'Thursday',
            'Friday',
            'Saturday',
            'Sunday',
          ],
          labels: {
            show: true,
          },
        },
        yaxis: {
          show: true,
          labels: {
            show: true,
            formatter(val) {
              return (
                val
                  .toFixed(2)
                  .replace('.', ',')
                  .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') + ' €'
              )
            },
          },
        },
        grid: {
          show: true,
        },
        legend: {
          show: false,
        },
      },
      series: [
        {
          name: 'PayCoin',
          data: [],
        },
        {
          name: 'PaySMS',
          data: [],
        },
        {
          name: 'PayCard',
          data: [],
        },
        {
          name: 'PayLoyalty',
          data: [],
        },
      ],
      isReady: false,
    }
  },
  computed: {
    ...mapGetters(['currentUserCompanyModule']),
  },
  mounted() {
    const mq = window.matchMedia('(min-width: 480px)')

    if (!mq.matches) {
      this.options.chart.type = 'area'
      this.options.xaxis.labels.show = false
      this.options.yaxis.show = false
    }

    if (this.currentUserCompanyModule[0] === 'gaming')
      this.options.legend.show = true

    this.series = this.chartData

    setTimeout(() => {
      this.isReady = true
    }, 500)
  },
}
</script>
