<template>
  <div>
    <div class="card card-custom gutter-b">
      <div class="card-header border-0 pt-5 pb-0">
        <h3 class="card-title font-weight-bolder text-dark">{{ $t('PAGES.LESSORS') }}</h3>
        <div class="card-toolbar" v-if=" this.currentUserRole === 'ROLE_ROOT' || this.currentUserRole === 'ROLE_SUPER_ADMIN' || this.currentUserPersonalInfo.email.includes(
                'davor.botincan@kidsdelux.hr'
              )" >
          <router-link
            to="/lessors/new"
            class="btn btn-primary font-weight-bolder"
          >
            <span class="svg-icon svg-icon-md">
              <inline-svg src="media/svg/icons/Electric/Outlet.svg" /></span
            >{{ $t('GENERAL.ADD') }}
          </router-link>
        </div>
      </div>
      <div class="card-body">
        <div v-if="!isLoading" class="row mb-6">
        <div class="col-md-12">
          <div class="form-group mb-6">
            <input
              v-model="lessorName"
              type="text"
              class="form-control"
              :placeholder="$t('ARTICLES.ENTER_LESSOR_NAME')"
              @input="filter(lessorName)"
            />
          </div>
        </div>
      </div>
      <div v-if="isLoading" class="col-12 text-center my-5 my-5 p-2 pt-5 bg-secondary">
        <div class="text-center my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong class="ml-4">{{ $t('GENERAL.LOADING') }}</strong>
        </div>
      </div>
      <div v-else-if="!isLoading && filteredLessors.length > 0" class="table-responsive mb-0 pb-0">
          <table
            class="table table-head-custom table-vertical-center table-head-bg"
          >
            <thead>
              <tr class="text-uppercase">
                <th class="px-6">{{ $t('GENERAL.NAME') }}</th>
                <th>{{ $t('GENERAL.ADDRESS') }}</th>
                <th>Oib</th>
                <th>@</th>
                <th class="text-right pr-6">{{ $t('GENERAL.ACTIONS') }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(lessor, key) in filteredLessors" :key="lessor.id">
                <td :class="{ 'border-top-0': key === 0 }" class="px-6">
                  <router-link
                    :to="'/lessors/edit/' + lessor.id"
                    class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
                    >{{ lessor.name }}</router-link
                  >
                </td>
                <td :class="{ 'border-top-0': key === 0 }">
                  {{ lessor.address }}
                </td>
                <td :class="{ 'border-top-0': key === 0 }">
                  {{ lessor.oib }}
                </td>
                <td :class="{ 'border-top-0': key === 0 }">
                  {{ lessor.email }}
                </td>
                <td
                  :class="{ 'border-top-0': key === 0 }"
                  class="text-right pr-6"
                >
                  <button
                    @click="showMoreInfoModal(lessor.id)"
                    class="btn btn-sm btn-default btn-text-primary btn-hover-primary btn-icon mr-2"
                  >
                    <span class="svg-icon svg-icon-md" v-b-tooltip.hover.top="$t('LESSORS.SHOW_INFO')">
                      <inline-svg src="media/svg/icons/Design/Target.svg" />
                    </span>
                  </button>
                  <router-link
                    :to="'/lessors/edit/' + lessor.id"
                    class="btn btn-sm btn-default btn-text-primary btn-hover-primary btn-icon mr-2"
                  >
                    <span class="svg-icon svg-icon-md" v-b-tooltip.hover.top="$t('LESSORS.EDIT_LESSOR')">
                      <inline-svg
                        src="media/svg/icons/General/Settings-1.svg"
                      />
                    </span>
                  </router-link>
                  <a
                    @click="deleteEntity(lessor.id)"
                    href="#"
                    class="btn btn-sm btn-default btn-text-primary btn-hover-primary btn-icon"
                  >
                    <span class="svg-icon svg-icon-md" v-b-tooltip.hover.top="$t('LESSORS.DELETE_LESSOR')">
                      <inline-svg src="media/svg/icons/General/Trash.svg" />
                    </span>
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
          <div v-if=" this.currentUserRole === 'ROLE_ROOT' || this.currentUserRole === 'ROLE_SUPER_ADMIN'" 
          class="mt-10 overflow-auto d-flex justify-content-center align-items-center">
            <b-pagination-nav
              :link-gen="linkGen"
              :number-of-pages="pagination.totalPages"
              use-router
              size="lg"
            ></b-pagination-nav>
          </div>
      </div>
      <div v-else-if="!isLoading && filteredLessors.length === 0" class="row" style="display: flex; align-items: center; justify-content: center; flex-direction: column; height: 100%;">
        <div class="col-12 pt-4 pb-6 text-center px-6 pl-10">
          <div style="display: flex; align-items: center; justify-content: center; flex-direction: column; height: 100%;">
            <p class="font-weight-bold" style="font-size: 24px;">No lessors.</p>
            <p style="font-size: 16px;">There are currently no lessors.</p>
          </div>
        </div>
      </div>
    </div>
    </div>
    <div class="row">
    <div class="col-12">
      <b-modal
        id="lessor_modal"
        body-class="text-left"
        centered
        size="lg"
        hide-header
        hide-footer
      >
        <div v-if="isLessorLoaded">
          <b-button
            class="btn btn-light-primary mb-3"
            block
            @click="$bvModal.hide('lessor_modal')"
          >
            <i class="fas fa-times"></i> {{ $t('GENERAL.CLOSE') }}
          </b-button>
          <div class="row mb-6">
            <div class="col-md-6">
              <span class="font-weight-bold">{{ $t('GENERAL.NAME') }}:</span><span>{{ lessor.name }}</span>
            </div>
            <div class="col-md-6">
              <span class="font-weight-bold">{{ $t('MAIN_MENU.COMPANY') }}: </span><span>{{ lessor.company }}</span>
            </div>
          </div>
          <div class="row mb-6">
            <div class="col-md-6">
              <span class="font-weight-bold">{{ $t('GENERAL.ADDRESS') }}: </span><span>{{ lessor.address }}</span>
            </div>
            <div class="col-md-6">
              <span class="font-weight-bold">OIB: </span><span>{{ lessor.oib }}</span>
            </div>
          </div>
          <div class="row mb-6">
            <div class="col-md-6">
              <span class="font-weight-bold">{{ $t('GENERAL.EMAIL') }}: </span><span>{{ lessor.email }}</span>
            </div>
            <div class="col-md-6">
              <span class="font-weight-bold">{{ $t('COMPANIES.NOTIFICATION_EMAIL') }}: </span><span>{{ lessor.meta.notificationEmail }}</span>
            </div>
          </div>
          <div class="row mb-6">
            <div class="col-md-6">
              <span class="font-weight-bold">{{ $t('LESSORS.CONTRACT_START_DATE') }}: </span><span>{{ lessor.meta.contractStartDate }}</span>
            </div>
            <div class="col-md-6">
              <span class="font-weight-bold">{{ $t('LESSORS.CONTRACT_END_DATE') }}: </span><span>{{ lessor.meta.contractEndDate }}</span>
            </div>
          </div>
          <div class="row mb-6">
            <div class="col-md-6">
              <span class="font-weight-bold">{{ $t('DEVICES.DEVICES_NUMBER') }}: </span><span>{{ lessor.meta.devicesNumber }}</span>
            </div>
          </div>
        </div>
      </b-modal>
    </div>
  </div>
  </div>
</template>

<script>
import ApiService from '@/core/services/api.service'
import { mapGetters } from 'vuex'

export default {
  name: 'LessorsList',
  data() {
    return {
      lessors: [],
      company: {},
      lessorName: '',
      filteredLessors: [],
      isLoading: true,
      isLessorLoaded: false,
      lessor: null,
      pagination: {
        totalPages: 0,
        currentPage: 1,
        itemsPerPage: 30,
      },
    }
  },
  computed: {
    ...mapGetters([
      'currentUserRole',
      'currentUserCompany',
      'currentUserPersonalInfo',
      'currentUserCompanyModule',
      'currentUserCompanyOib',
    ]),
    page() {
      return this.$route.query.page || 1
    },
  },
  mounted() {
    this.getLessors()
  },
  watch: {
    page() {
      this.getLessors()
    },
  },
  methods: {
    filter(value) {
      const searchValue = value.trim().toLowerCase();
      if (searchValue) {
        this.filteredLessors = this.filteredLessors.filter((item) =>
          item.name.toLowerCase().includes(searchValue)
        );
      } else {
        this.filteredLessors = this.lessors;
      }
    },
    linkGen(pageNum) {
      return pageNum === 1 ? '?' : `?page=${pageNum}`
    },
    getLessors() {
      this.isLoading = true;
      this.pagination.currentPage = this.$route.query.page || 1

      if (
        this.currentUserRole === 'ROLE_ROOT' ||
        this.currentUserRole === 'ROLE_SUPER_ADMIN'
      ) {
        ApiService.get(
          'lessors',
          `?pagination=true&itemsPerPage=${this.pagination.itemsPerPage}&page=${
            this.pagination.currentPage
          }`
        )
          .then(({ data }) => {
            this.totalItems = data['hydra:member'].length
            this.lessors = [...data['hydra:member']]
            this.pagination.totalPages = Math.ceil(
              data['hydra:totalItems'] / this.pagination.itemsPerPage
            )
          })
          .finally(() => {
            this.isLoading = false
          })
      }

       if (
        this.currentUserRole === 'ROLE_ADMIN' ||
        this.currentUserRole === 'ROLE_JUNIOR'
      ) {
        Promise.all([
          ApiService.get(`companies/${this.currentUserCompany.replace(
            '/api/v1/companies/',
            ''
          )}`, '?pagination=false'),
          ApiService.get('lessors', '?pagination=false')
        ])
          .then(([companiesResponse, lessorsResponse]) => {
            this.company = companiesResponse.data;
            const lessors = lessorsResponse.data['hydra:member'];

            if(this.company.id === '31e7920a-580e-4c96-9bcf-f46704868824') {
              this.lessors = lessors;
              this.filteredLessors = lessors
            } else {
              this.lessors = lessors.filter((item) => item.oib === this.company.oib);
              this.filteredLessors = lessors.filter((item) => item.oib === this.company.oib);
            }
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
    deleteEntity(id) {
      let confirmation = confirm('Zelite izbrisati ovaj Grad?')
      if (confirmation === false) return

      this.isLoaded = false
      ApiService.delete(`lessors/${id}`)
        .then(() => {
          this.getLessors()
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    showMoreInfoModal(lessorId) {
      ApiService.get('lessors/' + lessorId).then(({ data }) => {
        this.lessor = data
        this.isLessorLoaded = true
        this.$root.$emit('bv::show::modal', 'lessor_modal')
      })
    },
  },
}
</script>
