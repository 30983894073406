import Vue from 'vue'
import { format } from 'date-fns'
import parse from 'date-fns/parse'

Vue.filter('formatDate', function (value) {
  if (value) return format(new Date(value), 'dd.MM.yyyy.')
})

Vue.filter('formatTime', function (value) {
  if (value) return format(new Date(value), 'HH:mm')
})

Vue.filter('formatDateTime', function (value) {
  if (value) return format(new Date(value), 'dd.MM.yyyy @ HH:mm')
})

Vue.filter('dateParse', function (value) {
  // if (value) return format(new Date(value), 'dd.MM.yyyy @ HH:mm')
  if (value) return parse(value, "yyyy-MM-dd'T'HH:mm:ss", new Date())
})

Vue.filter('currencyFormat', function (value) {
  if (value) {
    return (
      value
        .toFixed(2)
        .replace('.', ',')
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') + ' €'
    )
  }
})

Vue.filter('currencyFormatKn', function (value) {
  if (value) {
    return (
      value
        .toFixed(2)
        .replace('.', ',')
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') + '€'
    )
  }
})

Vue.filter('moneyValueFormat', function (value) {
  if (value) {
    return (
      value
        .toFixed(2)
        .replace('.', ',')
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') 
    )
  }
})
