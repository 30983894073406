<template>
  <div>
    <div
      class="card card-custom gutter-b"
      style="height: 60vh; overflow-y: auto"
    >
      <div class="card-header border-0 py-5">
        <h3 class="card-title font-weight-bolder text-dark">
          Month
          <span class="ml-5"
            ><span
              class="label label-lg label-inline label-light-primary font-weight-bold"
              >{{ range.start | formatDate }}</span
            ></span
          >
          <span
            ><span class="flex-shrink-0 my-2 mx-3"
              ><i class="la la-arrow-right"></i></span
          ></span>
          <span
            ><span
              class="label label-lg label-inline label-light-primary font-weight-bold"
              >{{ range.end | formatDate }}</span
            ></span
          >
        </h3>
      </div>
      <div class="card-body" style="display: flex; align-items: center">
        <VueApexCharts
          v-if="isReady"
          :options="options"
          :series="series"
          height="100%"
          width="100%"
          style="height: 100%; width: 100%"
        />
      </div>
    </div>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import { mapGetters } from 'vuex'

export default {
  name: 'MonthTrafficRefactored',
  components: {
    VueApexCharts,
  },
  props: {
    chartData: {
      type: Array,
    },
    range: {
      type: Object,
    },
  },
  data() {
    return {
      options: {
        chart: {
          type: 'bar',
          height: 350,
          stacked: true,
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: true,
          offsetX: -2,
          offsetY: 7,
          formatter(val) {
            if (val) {
              return (
                val
                  .toFixed(2)
                  .replace('.', ',')
                  .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') + ' €'
              )
            }
            return val
          },
        },
        xaxis: {
          categories: [],
          labels: {
            show: true,
          },
        },
        yaxis: {
          show: true,
          labels: {
            show: true,
            formatter(val) {
              if (val) {
                return (
                  val
                    .toFixed(2)
                    .replace('.', ',')
                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') + ' €'
                )
              }
              return val
            },
          },
        },
        legend: {
          show: false,
        },
      },
      series: [],
      isReady: false,
    }
  },
  computed: {
    ...mapGetters(['currentUserCompanyModule']),
  },
  mounted() {
    const mq = window.matchMedia('(min-width: 480px)')

    if (!mq.matches) {
      this.options.chart.type = 'area'
      this.options.xaxis.labels.show = false
      this.options.yaxis.show = false
    }

    if (this.currentUserCompanyModule[0] === 'gaming')
      this.options.legend.show = true

    const months = this.getMonthsInPeriod()
    this.setCategories(months)

    this.chartData.map((data) => {
      const filteredArray = months.map((month) => data.data[month])
      const seriesData = {
        name: data.name,
        data: filteredArray,
      }
      this.series.push(seriesData)
    })

    setTimeout(() => {
      this.isReady = true
    }, 500)
  },
  methods: {
    setCategories(months) {
      months.map((month) => {
        switch (month) {
          case 0:
            if (this.options.xaxis.categories.includes('January') === false)
              this.options.xaxis.categories.push('January')
            break
          case 1:
            if (this.options.xaxis.categories.includes('February') === false)
              this.options.xaxis.categories.push('February')
            break
          case 2:
            if (this.options.xaxis.categories.includes('March') === false)
              this.options.xaxis.categories.push('March')
            break
          case 3:
            if (this.options.xaxis.categories.includes('April') === false)
              this.options.xaxis.categories.push('April')
            break
          case 4:
            if (this.options.xaxis.categories.includes('May') === false)
              this.options.xaxis.categories.push('May')
            break
          case 5:
            if (this.options.xaxis.categories.includes('June') === false)
              this.options.xaxis.categories.push('June')
            break
          case 6:
            if (this.options.xaxis.categories.includes('July') === false)
              this.options.xaxis.categories.push('July')
            break
          case 7:
            if (this.options.xaxis.categories.includes('August') === false)
              this.options.xaxis.categories.push('August')
            break
          case 8:
            if (this.options.xaxis.categories.includes('Semptember') === false)
              this.options.xaxis.categories.push('Semptember')
            break
          case 9:
            if (this.options.xaxis.categories.includes('October') === false)
              this.options.xaxis.categories.push('October')
            break
          case 10:
            if (this.options.xaxis.categories.includes('November') === false)
              this.options.xaxis.categories.push('November')
            break
          case 11:
            if (this.options.xaxis.categories.includes('December') === false)
              this.options.xaxis.categories.push('December')
            break
          default:
            break
        }
      })
    },
    getMonthsInPeriod() {
      const startDate = new Date(this.range.start)
      const endDate = new Date(this.range.end)

      const months = []

      let currentMonth = startDate.getMonth()
      let currentYear = startDate.getFullYear()
      while (
        currentYear < endDate.getFullYear() ||
        (currentYear === endDate.getFullYear() &&
          currentMonth <= endDate.getMonth())
      ) {
        months.push(currentMonth)
        currentMonth++

        if (currentMonth > 11) {
          currentMonth = 0
          currentYear++
        }
      }

      return months
    },
  },
}
</script>
