<template>
  <div>
    <div class="card card-custom gutter-b">
      <div class="card-header border-0 py-5">
        <h3 class="card-title font-weight-bolder text-dark">
          Report By Devices:
          <span class="ml-5"
            ><span
              class="label label-lg label-inline label-light-primary font-weight-bold"
              >{{ range.start | formatDate }}</span
            ></span
          >
          <span
            ><span class="flex-shrink-0 my-2 mx-3"
              ><i class="la la-arrow-right"></i></span
          ></span>
          <span
            ><span
              class="label label-lg label-inline label-light-primary font-weight-bold"
              >{{ range.end | formatDate }}</span
            ></span
          >
        </h3>
        <div class="card-toolbar">
          <button
            @click="exportAsExcel"
            class="btn btn-light-primary font-weight-bold px-10"
          >
            Export .xlsx
          </button>
        </div>
      </div>
      <div class="card-body pt-0">
        <DeviceReportTableRefactored
          v-if="isGenerated"
          :traffic="traffic"
          :selectedDevices="selected"
          :isTokenColumnVisible="true"
          @data-sent="handleGeneratedResults"
        />
        <div
          v-else-if="
            isGenerated && this.traffic.devicesWithTraffic.length === 0
          "
          class="row"
          style="
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            height: 100%;
          "
        >
          <div class="col-12 pt-4 pb-6 text-center px-6 pl-10">
            <div
              style="
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                height: 100%;
              "
            >
              <p class="font-weight-bold" style="font-size: 24px">
                No transactions.
              </p>
              <p style="font-size: 16px">
                There are no transactions for the selected period.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import * as XLSX from 'xlsx'
import DeviceReportTableRefactored from '../../../content/components/device-report-table-refactored/DeviceReportTableRefactored.vue'

export default {
  name: 'LocationsTrafficRefactored',
  props: {
    traffic: {
      type: Object,
    },
    selected: {
      type: Array,
    },
    range: {
      type: Object,
    },
  },
  components: {
    DeviceReportTableRefactored,
  },
  data() {
    return {
      isGenerated: false,
      isDevicesWithoutTrafficVisible: false,
      exportData: [],
    }
  },
  computed: {
    ...mapGetters(['currentUserCompanyOib']),
  },
  mounted() {
    setTimeout(() => {
      this.isGenerated = true
    }, 2000)
  },
  methods: {
    exportAsExcel() {
      const ws = XLSX.utils.json_to_sheet(this.exportData)
      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1')

      XLSX.writeFile(wb, 'finacial-analysis-by-device.xlsx')
    },
    handleGeneratedResults(data) {
      this.exportData = data
    },
  },
}
</script>
