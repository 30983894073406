<template>
  <div>
    <div class="card card-custom gutter-b">
      <div class="card-header border-0 py-5">
        <h3 class="card-title">
          <span class="card-label font-weight-bolder text-dark">Locations</span>
        </h3>
        <div class="card-toolbar">
          <router-link
            to="/locations/new"
            class="btn btn-primary font-weight-bolder"
          >
            <span class="svg-icon svg-icon-md">
              <inline-svg src="media/svg/icons/Map/Position.svg" />
            </span>
            <span>Add New Location</span>
          </router-link>
        </div>
      </div>

      <div class="card-body py-0">
        <div class="input-group mb-4">
          <input
            type="text"
            v-model="locationSearchInput"
            class="form-control form-control-solid form-control-lg"
            placeholder="Search location"
            @change="searchLocation()"
          />
          <b-button variant="primary" class="ml-4" @click="searchLocation()">{{
            $t('GENERAL.SEARCH')
          }}</b-button>
        </div>
        <div
          v-if="isLoading"
          class="col-12 text-center my-5 p-2 pt-5 bg-secondary"
        >
          <div class="text-center my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong class="ml-4">{{ $t('GENERAL.LOADING') }}</strong>
          </div>
        </div>
        <div v-if="!isLoading && locations.length > 0" class="table-responsive">
          <table
            class="table table-head-custom table-vertical-center table-head-bg"
          >
            <thead>
              <tr class="text-uppercase">
                <th class="pl-6">
                  <span
                    @click="searchLocation('titleOrder')"
                    class="cursor-pointer"
                  >
                    <span
                      class="pt-2"
                      :class="{ 'text-primary': titleOrder.isActive }"
                    >
                      Location Name</span
                    >
                    <span
                      class="svg-icon svg-icon-sm pl-2"
                      :class="{
                        'svg-icon-primary': titleOrder.isActive,
                      }"
                    >
                      <inline-svg
                        v-if="titleOrder.query === 'asc'"
                        src="media/svg/icons/Navigation/Down-2.svg"
                      />
                      <inline-svg
                        v-else
                        src="media/svg/icons/Navigation/Up-2.svg"
                      />
                    </span>
                  </span>
                </th>
                <th>
                  <span
                    @click="searchLocation('addressOrder')"
                    class="cursor-pointer"
                  >
                    <span
                      class="pt-2"
                      :class="{ 'text-primary': addressOrder.isActive }"
                    >
                      Address</span
                    >
                    <span
                      class="svg-icon svg-icon-sm pl-2"
                      :class="{
                        'svg-icon-primary': addressOrder.isActive,
                      }"
                    >
                      <inline-svg
                        v-if="addressOrder.query === 'asc'"
                        src="media/svg/icons/Navigation/Down-2.svg"
                      />
                      <inline-svg
                        v-else
                        src="media/svg/icons/Navigation/Up-2.svg"
                      />
                    </span>
                  </span>
                </th>
                <th v-if="currentUserCompanyModule[0] === 'gaming'">
                  <span
                    @click="searchLocation('isActiveOrder')"
                    class="cursor-pointer"
                  >
                    <span
                      class="pt-2"
                      :class="{ 'text-primary': isActiveOrder.isActive }"
                    >
                      Status</span
                    >
                    <span
                      class="svg-icon svg-icon-sm pl-2"
                      :class="{
                        'svg-icon-primary': isActiveOrder.isActive,
                      }"
                    >
                      <inline-svg
                        v-if="isActiveOrder.query === 'asc'"
                        src="media/svg/icons/Navigation/Down-2.svg"
                      />
                      <inline-svg
                        v-else
                        src="media/svg/icons/Navigation/Up-2.svg"
                      />
                    </span>
                  </span>
                </th>
                <th v-if="currentUserCompanyModule[0] === 'gaming'">
                  <span
                    @click="searchLocation('responsibleWorkerOrder')"
                    class="cursor-pointer"
                  >
                    <span
                      class="pt-2"
                      :class="{
                        'text-primary': responsibleWorkerOrder.isActive,
                      }"
                    >
                      {{ $t('LOCATION_MANAGEMENT.RESPONSIBLE_WORKER') }}</span
                    >
                    <span
                      class="svg-icon svg-icon-sm pl-2"
                      :class="{
                        'svg-icon-primary': responsibleWorkerOrder.isActive,
                      }"
                    >
                      <inline-svg
                        v-if="responsibleWorkerOrder.query === 'asc'"
                        src="media/svg/icons/Navigation/Down-2.svg"
                      />
                      <inline-svg
                        v-else
                        src="media/svg/icons/Navigation/Up-2.svg"
                      />
                    </span>
                  </span>
                </th>
                <!-- <th v-if="currentUserCompanyModule[0] === 'gaming'">
                  <span>{{
                    $t('LOCATION_MANAGEMENT.NEW_RESPONSIBLE_WORKER')
                  }}</span>
                </th> -->
                <th v-if="currentUserCompanyModule[0] === 'gaming'">
                  <span
                    @click="searchLocation('lessorNameOrder')"
                    class="cursor-pointer"
                  >
                    <span
                      class="pt-2"
                      :class="{ 'text-primary': lessorNameOrder.isActive }"
                    >
                      {{ $t('GENERAL.LESSOR') }}</span
                    >
                    <span
                      class="svg-icon svg-icon-sm pl-2"
                      :class="{
                        'svg-icon-primary': lessorNameOrder.isActive,
                      }"
                    >
                      <inline-svg
                        v-if="lessorNameOrder.query === 'asc'"
                        src="media/svg/icons/Navigation/Down-2.svg"
                      />
                      <inline-svg
                        v-else
                        src="media/svg/icons/Navigation/Up-2.svg"
                      />
                    </span>
                  </span>
                </th>
                <th class="text-right pr-6">
                  <span>{{ $t('GENERAL.ACTIONS') }}</span>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(location, key) in locations" :key="key">
                <td class="pl-6" :class="{ 'border-top-0': key === 0 }">
                  <router-link
                    :to="'/locations/edit/' + location.id"
                    class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
                    >{{ location.title }}</router-link
                  >
                  <span
                    class="text-muted font-weight-bold text-muted d-block"
                    >{{ location.description }}</span
                  >
                </td>
                <td :class="{ 'border-top-0': key === 0 }">
                  <div
                    class="d-flex flex-column w-100 mr-2 text-muted font-weight-500"
                  >
                    {{ location.address.name }}
                  </div>
                </td>
                <td :class="{ 'border-top-0': key === 0 }">
                  <div
                    :class="[
                      'mr-2',
                      'label',
                      'label-lg',
                      'label-inline',
                      location.isActive
                        ? 'label-light-success'
                        : 'label-light-danger',
                    ]"
                  >
                    {{ location.isActive ? 'Active' : 'Disabled' }}
                  </div>
                </td>
                <td :class="{ 'border-top-0': key === 0 }">
                  <div
                    class="d-flex flex-column w-100 mr-2 text-muted font-weight-500"
                  >
                    {{ location.responsibleWorker }}
                  </div>
                </td>
                <!-- <td :class="{ 'border-top-0': key === 0 }">
                  <div
                    class="d-flex flex-column w-100 mr-2 text-muted font-weight-500"
                  >
                    {{
                      location.newResponsibleWorker
                        ? `${location.newResponsibleWorker.name} ${location.newResponsibleWorker.surname}`
                        : ''
                    }}
                  </div>
                </td> -->
                <td
                  v-if="currentUserCompanyModule[0] === 'gaming'"
                  :class="{ 'border-top-0': key === 0 }"
                >
                  <div
                    v-if="location.lessor"
                    class="d-flex flex-column w-100 mr-2 text-muted font-weight-500"
                  >
                    {{ location.lessor.name }}
                  </div>
                </td>
                <td
                  class="text-right pr-6"
                  :class="{ 'border-top-0': key === 0 }"
                >
                  <router-link
                    :to="'/locations/edit/' + location.id"
                    class="btn btn-sm btn-default btn-text-primary btn-hover-primary btn-icon mr-2"
                  >
                    <span
                      class="svg-icon svg-icon-md"
                      v-b-tooltip.hover.top="$t('LOCATIONS.EDIT_LOCATION')"
                    >
                      <inline-svg
                        src="media/svg/icons/General/Settings-1.svg"
                      />
                    </span>
                  </router-link>
                  <a
                    @click="deleteEntity(location.id)"
                    href="#"
                    class="btn btn-sm btn-default btn-text-primary btn-hover-primary btn-icon"
                  >
                    <span
                      class="svg-icon svg-icon-md"
                      v-b-tooltip.hover.top="$t('LOCATIONS.DELETE_LOCATION')"
                    >
                      <inline-svg src="media/svg/icons/General/Trash.svg" />
                    </span>
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
          <div
            class="mt-10 overflow-auto d-flex justify-content-center align-items-center"
          >
            <b-pagination-nav
              :link-gen="linkGen"
              :number-of-pages="pagination.totalPages"
              use-router
              size="lg"
            ></b-pagination-nav>
          </div>
        </div>
        <div
          v-else-if="!isLoading && locations.length === 0"
          class="row"
          style="
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            height: 100%;
          "
        >
          <div class="col-12 pt-4 pb-6 text-center px-6 pl-10">
            <div
              style="
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                height: 100%;
              "
            >
              <p class="font-weight-bold" style="font-size: 24px">
                No locations.
              </p>
              <p style="font-size: 16px">There are currently no locations.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from '@/core/services/api.service'

import { mapGetters } from 'vuex'
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'

export default {
  name: 'LocationsList',
  data() {
    return {
      locations: [],
      locationSearchInput: '',
      titleOrder: {
        query: 'asc',
        isActive: false,
        name: 'title',
      },
      addressOrder: {
        query: 'asc',
        isActive: false,
        name: 'company.address',
      },
      isActiveOrder: {
        query: 'asc',
        isActive: false,
        name: 'isActive',
      },
      responsibleWorkerOrder: {
        query: 'asc',
        isActive: false,
        name: 'responsibleWorker',
      },
      lessorNameOrder: {
        query: 'asc',
        isActive: false,
        name: 'lessor.name',
      },
      isLoading: true,
      pagination: {
        totalPages: 0,
        itemsPerPage: 30,
      },
    }
  },
  computed: {
    ...mapGetters([
      'currentUserRole',
      'currentUserCompany',
      'currentUserCompanyModule',
    ]),
    page() {
      return this.$route.query.page || 1
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: 'Locations', route: '' }])
    this.getLocations()
  },
  watch: {
    page() {
      this.getLocations()
    },
  },
  methods: {
    linkGen(pageNum) {
      return pageNum === 1 ? '?' : `?page=${pageNum}`
    },
    getLocations() {
      const currentPage = this.$route.query.page || 1
      this.isLoading = true

      if (
        this.currentUserRole === 'ROLE_ROOT' ||
        this.currentUserRole === 'ROLE_SUPER_ADMIN'
      ) {
        ApiService.get(
          'locations',
          `?itemsPerPage=${this.pagination.itemsPerPage}&page=${currentPage}`
        )
          .then(({ data }) => {
            this.locations = [...data['hydra:member']]
            this.pagination.totalPages = Math.ceil(
              data['hydra:totalItems'] / this.pagination.itemsPerPage
            )
          })
          .finally(() => {
            this.isLoading = false
          })
      }

      if (this.currentUserRole === 'ROLE_ADMIN') {
        ApiService.get(
          'locations',
          `?company=${this.currentUserCompany}&itemsPerPage=${this.pagination.itemsPerPage}&page=${currentPage}`
        )
          .then(({ data }) => {
            this.locations = [...data['hydra:member']]
            this.pagination.totalPages = Math.ceil(
              data['hydra:totalItems'] / this.pagination.itemsPerPage
            )
          })
          .finally(() => {
            this.isLoading = false
          })
      }
    },

    deleteEntity(id) {
      let confirmation = confirm('Zelite izbrisati ovog korisnika?')
      if (confirmation === false) return

      this.isLoading = true
      ApiService.delete(`locations/${id}`)
        .then(() => {
          if (this.currentUserRole === 'ROLE_ROOT') {
            ApiService.get('locations').then((response) => {
              this.locations = [...response.data['hydra:member']]
            })
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    searchLocation(order) {
      this.searchQuery = ''

      if (typeof order === 'string' && order) {
        this[order].isActive = true
        if (this[order].query === 'asc') this[order].query = 'desc'
        else this[order].query = 'asc'
      }

      const currentPage = this.$route.query.page || 1

      if (this.titleOrder.isActive) {
        this.searchQuery += `&order[${this.titleOrder.name}]=${this.titleOrder.query}`
      }
      if (this.addressOrder.isActive) {
        this.searchQuery += `&order[${this.addressOrder.name}]=${this.addressOrder.query}`
      }
      if (this.isActiveOrder.isActive) {
        this.searchQuery += `&order[${this.isActiveOrder.name}]=${this.isActiveOrder.query}`
      }
      if (this.responsibleWorkerOrder.isActive) {
        this.searchQuery += `&order[${this.responsibleWorkerOrder.name}]=${this.responsibleWorkerOrder.query}`
      }
      if (this.lessorNameOrder.isActive) {
        this.searchQuery += `&order[${this.lessorNameOrder.name}]=${this.lessorNameOrder.query}`
      }
      if (this.locationSearchInput)
        this.searchQuery += `&title=${this.locationSearchInput}`

      this.isLoading = true
      this.locations = []

      if (
        this.currentUserRole === 'ROLE_ROOT' ||
        this.currentUserRole === 'ROLE_SUPER_ADMIN'
      ) {
        ApiService.get(
          'locations',
          `?itemsPerPage=${this.pagination.itemsPerPage}&page=${currentPage}${this.searchQuery}`
        )
          .then(({ data }) => {
            this.locations = [...data['hydra:member']]
            this.pagination.totalPages = Math.ceil(
              data['hydra:totalItems'] / this.pagination.itemsPerPage
            )
          })
          .finally(() => {
            this.isLoading = false
          })
      }

      if (this.currentUserRole === 'ROLE_ADMIN') {
        ApiService.get(
          'locations',
          `?company=${this.currentUserCompany}&itemsPerPage=${this.pagination.itemsPerPage}&page=${currentPage}${this.searchQuery}`
        )
          .then(({ data }) => {
            this.locations = [...data['hydra:member']]
            this.pagination.totalPages = Math.ceil(
              data['hydra:totalItems'] / this.pagination.itemsPerPage
            )
          })
          .finally(() => {
            this.isLoading = false
          })
      }
    },
  },
}
</script>
