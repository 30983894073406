<template>
  <div>
    <div
      class="card card-custom gutter-b"
      style="height: 60vh; overflow-y: auto; width: 100%"
    >
      <div class="card-header d-flex align-items-center">
        <h3 class="card-title">
          Year
          <span class="ml-5"
            ><span
              class="label label-lg label-inline label-light-primary font-weight-bold"
              >{{ range.start | formatDate }}</span
            ></span
          >
          <span
            ><span class="flex-shrink-0 my-2 mx-3"
              ><i class="la la-arrow-right"></i></span
          ></span>
          <span
            ><span
              class="label label-lg label-inline label-light-primary font-weight-bold"
              >{{ range.end | formatDate }}</span
            ></span
          >
        </h3>
      </div>
      <div
        class="card-body"
        style="height: 60vh; display: flex; align-items: center; width: 100%"
      >
        <apexchart
          v-if="isReady"
          :options="options"
          :series="series"
          width="100%"
          height="100%"
          style="height: 100%; width: 100%"
        />
      </div>
    </div>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import { mapGetters } from 'vuex'

export default {
  name: 'YearTrafficRefactored',
  components: {
    apexchart: VueApexCharts,
  },
  props: {
    chartData: {
      type: Array,
    },
    range: {
      type: Object,
    },
  },
  data() {
    return {
      transactions: [],
      coinTransactions: [],
      smsTransactions: [],
      cardTransactions: [],
      loyaltyTransactions: [],
      options: {
        chart: {
          type: 'bar',
          height: 350,
          stacked: true,
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: true,
          offsetX: -2,
          offsetY: 7,
          formatter(val) {
            return (
              val
                .toFixed(2)
                .replace('.', ',')
                .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') + ' €'
            )
          },
        },
        xaxis: {
          categories: [],
        },
        yaxis: {
          labels: {
            show: true,
            formatter(val) {
              return (
                val
                  .toFixed(2)
                  .replace('.', ',')
                  .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') + ' €'
              )
            },
          },
        },
        legend: {
          show: false,
        },
      },
      series: [
        {
          name: 'PayCoin',
          data: [],
        },
        {
          name: 'PaySMS',
          data: [],
        },
        {
          name: 'PayCard',
          data: [],
        },
        {
          name: 'PayLoyalty',
          data: [],
        },
      ],
      isReady: false,
    }
  },
  computed: {
    ...mapGetters(['currentUserCompanyModule']),
  },
  mounted() {
    if (this.currentUserCompanyModule[0] === 'gaming')
      this.options.legend.show = true

    this.series = this.chartData
    const years = this.getYearsInPeriod()

    years.map((year) => {
      this.options.xaxis.categories.push(year)
    })

    setTimeout(() => {
      this.isReady = true
    }, 500)
  },
  methods: {
    getYearsInPeriod() {
      const startDate = new Date(this.range.start)
      const endDate = new Date(this.range.end)

      const years = []

      let currentYear = startDate.getFullYear()
      const endYear = endDate.getFullYear()

      while (currentYear <= endYear) {
        years.push(currentYear)
        currentYear++
      }

      return years
    },
  },
}
</script>
