<template>
  <div>
    <div
      class="d-flex flex-column pt-12 bgi-size-cover bgi-no-repeat rounded-top"
      :style="{ backgroundImage: `url(${backgroundImage})` }"
    >
      <h4 class="d-flex flex-center rounded-top">
        <span class="text-dark">{{
          $t('DROPDOWN_NOTIFICATION.UNVERIFIED_DEPOSITS')
        }}</span>
        <span
          class="btn btn-text btn-danger btn-sm font-weight-bold btn-font-md ml-2"
        >
          {{ this.unverifiedDepositNumber }}
        </span>
      </h4>

      <div class="d-flex flex-center flex-column">
        <router-link to="/deposits/list" class="btn btn-sm btn-primary px-10">{{
          $t('MAIN_MENU.VIEW')
        }}</router-link>
      </div>
    </div>

    <div v-if="unverifiedDeposits.length > 0">
      <perfect-scrollbar
        class="p-4 scroll my-4"
        style="max-height: 40vh; position: relative"
      >
        <template v-for="(deposit, i) in unverifiedDeposits">
          <div class="p-4" v-bind:key="i">
              <div class="navi-text">
                <div class="text-muted">
                  <span class="text-dark font-weight-bold mr-3"
                    >VRIJEME PRAŽNJENJA:
                  </span>
                  <span>{{ deposit.created_at | formatDateTime }}</span>
                </div>
                <div class="text-muted">
                  <span class="text-dark font-weight-bold mr-3"
                    >NAZIV UREĐAJA:
                  </span>
                  <span>{{ deposit.device_name }}</span>
                </div>
                <div class="text-muted">
                  <span class="text-dark font-weight-bold mr-3"
                    >LOKACIJA:
                  </span>
                  <span>{{ deposit.location_name }}</span>
                </div>
                <div class="text-muted">
                  <span class="text-dark font-weight-bold mr-3">AMOUNT: </span>
                  <span>{{ deposit.amount }}</span>
                </div>
                <div class="text-muted">
                  <span class="text-dark font-weight-bold mr-3"
                    >TOKEN AMOUNT:
                  </span>
                  <span>{{ deposit.token_amount }}</span>
                </div>
                <div class="text-muted">
                  <span class="text-dark font-weight-bold mr-3"
                    >REPORTED TOKEN AMOUNT:
                  </span>
                  <span>{{ deposit.reported_token_amount || 0 }}</span>
                </div>
              </div>
            
            </div>
        </template>
      </perfect-scrollbar>
    </div>
    <div v-else class="d-flex flex-center text-center text-muted my-4">
      <p class="m-0">{{ $t('DROPDOWN_NOTIFICATION.ALL_VERIFIED') }}</p>
    </div>
  </div>
</template>
<script>
export default {
  name: 'DropdownUnverifiedDepositNotification',
  props: {
    unverifiedDepositNumber: {
      type: Number,
      required: true,
    },
    unverifiedDeposits: {
      type: Array,
      required: true,
    },
  },
  computed: {
    backgroundImage() {
      return process.env.BASE_URL + 'media/misc/bg-1.jpg'
    },
  },
}
</script>
